import React from 'react';

type Props = {
    children?: React.ReactNode;
};

const PageBody: React.FC<Props> = ({ children }) => {
    return (
        <div className="container-xxl">
            <div className="row justify-content-center">{children}</div>
        </div>
    );
};

export default PageBody;
