import React, { useEffect, useRef, useState } from 'react';
import PageBody from '../../Components/UI/PageBody';
import BodyCard from '../../Components/UI/BodyCard';
import Wizard, { StepsDataType } from '../../Components/UI/Wizard';
import Input, { CustomeInputRef } from '../../Components/Inputs/Input';
import moment from 'moment';
import Dropdown from '../../Components/Inputs/Dropdown';
import { checkAllBlankInputWithRef } from '../../Utils/helper';
import { CityDataType, PageDataWithEdit, StatesDataType } from '../../DataTypes/Global';
import CommonService from '../../Services/CommonService';
import CheckBox from '../../Components/Inputs/CheckBox';
import { AmenitiesData } from '../../Utils/Constant';
import ImageInput from '../../Components/Inputs/ImageInput';
import { HomestayDataType } from '../../DataTypes/Customer';
import CustomerService from '../../Services/CustomerService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useToast from '../../Components/Toast/useToast';

const CreateHomestay = () => {
    const { id } = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        isEdit = false, // Provide default value
        ...item
    } = (location.state as PageDataWithEdit<HomestayDataType>) || {};
    console.log('item', item);

    const basicDataInputRef = useRef<Array<React.RefObject<CustomeInputRef>>>([
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef()
    ]);

    const addressDataInputRef = useRef<Array<React.RefObject<CustomeInputRef>>>([
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef()
    ]);

    const [stepData, setStepData] = useState<Array<StepsDataType>>([
        {
            name: 'Basic Details',
            completed: false
        },
        {
            name: 'Address Details',
            completed: false
        },
        {
            name: 'Amenities',
            completed: false
        },
        {
            name: 'Images',
            completed: false
        }
    ]);
    const [allState, setAllState] = useState<Array<StatesDataType>>([]);
    const [allCity, setAllCity] = useState<Array<CityDataType>>([]);
    const [allAmenities, setAllAmenities] = useState<Array<any>>(
        AmenitiesData.reduce((prev, current: any) => {
            current.isSelected = false;

            return [...prev, current];
        }, new Array<any>())
    );

    const [name, setName] = useState<string>('');
    const [premisesNo, setPremisesNo] = useState<string>('');
    const [phNo, setPhNo] = useState<string>('');
    const [totalFloors, setTotalFloors] = useState<string>('');
    const [checkInTime, setCheckInTime] = useState<string>(
        moment('10:00 AM', 'hh:mm a').format('HH:mm')
    );
    const [checkOutTime, setCheckOutTime] = useState<string>(
        moment('09:00 AM', 'hh:mm a').format('HH:mm')
    );

    const [address1, setAddress1] = useState<string>('');
    const [address2, setAddress2] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [pinCode, setPinCode] = useState<string>('');
    const [landmark, setLandmark] = useState<string>('');
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        getAllState();
    }, []);

    useEffect(() => {
        console.log('item', item);
        if (isEdit) {
            setName(item.hotelName);
            setPremisesNo(item.premisesNo ?? '');
            setPhNo(item.phoneNo.toString());
            setTotalFloors(item.floor.toString());
            setCheckInTime(moment(item.checkInTime).format('HH:mm'));
            setCheckOutTime(moment(item.checkOutTime).format('HH:mm'));
            setAddress1(item.address1);
            setAddress2(item.address2);
            setState(item.state);
            getAllCity(item.state);
            setCity(item.city);
            setPinCode(item.postalCode.toString());
            setLandmark(item.landmark ?? '');
            setImage(item.image[0]);
            setAllAmenities((state) => {
                state.forEach((element, index) => {
                    if (item.facilities.includes(element.slug)) {
                        state[index].isSelected = true;
                    }
                });

                return JSON.parse(JSON.stringify(state));
            });
        }
    }, [isEdit]);

    const getAllState = () => {
        CommonService.getAllStates()
            .then((result) => {
                if (result && result.status) {
                    setAllState(result.data);
                }
            })
            .catch((error) => console.log('error', error));
    };

    const getAllCity = (stateId: string) => {
        CommonService.getAllCity(stateId ?? '')
            .then((result) => {
                if (result && result.status) {
                    setAllCity(result.data);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const onSubmit = () => {
        let data: HomestayDataType = {
            address1,
            address2,
            checkInTime: moment(checkInTime, 'HH:mm').format(),
            checkOutTime: moment(checkOutTime, 'HH:mm').format(),
            city,
            facilities: allAmenities
                .filter((it) => it.isSelected)
                .reduce((prev, current) => {
                    return [...prev, current.slug];
                }, new Array<string>()),
            floor: Number(totalFloors),
            hotelName: name,
            image: [
                image.trim() === ''
                    ? 'https://img.icons8.com/wired/100/building.png'
                    : image
            ],
            phoneNo: Number(phNo),
            postalCode: Number(pinCode),
            state,
            landmark,
            premisesNo: premisesNo.trim() === '' ? undefined : premisesNo
        };

        CustomerService.createCustomerHomestay(id ?? '', data)
            .then((result) => {
                if (result && result.status) {
                    toast.show('Homestay Added Successfully!!!', 'success');
                    navigate(-1);
                }
            })
            .catch((error) => console.log('error', error));
    };

    return (
        <PageBody>
            <BodyCard title="Create Homestay">
                <Wizard
                    steps={stepData}
                    showTitle={false}
                    nextAfterComplete={true}
                    onNextCheckForComplete={(stepIndex) => {
                        if (stepIndex === 0) {
                            if (checkAllBlankInputWithRef(basicDataInputRef.current)) {
                                setStepData((state) => {
                                    state[stepIndex].completed = true;

                                    return JSON.parse(JSON.stringify(state));
                                });

                                return true;
                            }
                        } else if (stepIndex === 1) {
                            if (checkAllBlankInputWithRef(addressDataInputRef.current)) {
                                setStepData((state) => {
                                    state[stepIndex].completed = true;

                                    return JSON.parse(JSON.stringify(state));
                                });

                                return true;
                            }
                        } else if (stepIndex === 2) {
                            if (allAmenities.findIndex((it) => it.isSelected) >= 0) {
                                setStepData((state) => {
                                    state[stepIndex].completed = true;

                                    return JSON.parse(JSON.stringify(state));
                                });
                                return true;
                            }
                        }
                        return false;
                    }}
                    stepsElement={[
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <Input
                                    ref={basicDataInputRef.current[0]}
                                    label="Hotel Name *"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4">
                                <Input
                                    label="Premises No"
                                    value={premisesNo}
                                    onChange={(e) => setPremisesNo(e.target.value)}
                                />
                            </div>

                            <div className="col-md-4">
                                <Input
                                    ref={basicDataInputRef.current[1]}
                                    label="Reception Phone Number *"
                                    value={phNo}
                                    type="number"
                                    onChange={(e) => setPhNo(e.target.value)}
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4">
                                <Input
                                    ref={basicDataInputRef.current[2]}
                                    label="Toal number of Floors excluding Rooftop *"
                                    value={totalFloors}
                                    onChange={(e) => setTotalFloors(e.target.value)}
                                    type="number"
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4">
                                <Input
                                    ref={basicDataInputRef.current[3]}
                                    label="Check-in Time *"
                                    value={checkInTime}
                                    onChange={(e) => setCheckInTime(e.target.value)}
                                    type="time"
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4">
                                <Input
                                    ref={basicDataInputRef.current[4]}
                                    label="Check-out Time *"
                                    value={checkOutTime}
                                    onChange={(e) => setCheckOutTime(e.target.value)}
                                    type="time"
                                    required={true}
                                />
                            </div>
                        </div>,
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <Input
                                    ref={addressDataInputRef.current[0]}
                                    label="Address Line 1 (building no, Street/Road name) *"
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4">
                                <Input
                                    ref={addressDataInputRef.current[1]}
                                    label="Address Line 2 (Area/Locality name) *"
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4">
                                <Dropdown
                                    ref={addressDataInputRef.current[2]}
                                    label="State *"
                                    options={allState}
                                    value={state}
                                    onChange={(e) => {
                                        setState(e.target.value);
                                        getAllCity(e.target.value);
                                    }}
                                    labelKey="name"
                                    valueKey="_id"
                                    required={true}
                                />
                            </div>

                            <div className="col-md-4">
                                <Dropdown
                                    ref={addressDataInputRef.current[3]}
                                    label="City *"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    options={allCity}
                                    labelKey="name"
                                    valueKey="_id"
                                    required={true}
                                />
                            </div>
                            <div className="col-md-4">
                                <Input
                                    ref={addressDataInputRef.current[4]}
                                    label="Postal Code *"
                                    value={pinCode}
                                    onChange={(e) => setPinCode(e.target.value)}
                                    type="number"
                                    required={true}
                                />
                            </div>
                            <div className="col-md-4">
                                <Input
                                    label="Landmark"
                                    value={landmark}
                                    onChange={(e) => setLandmark(e.target.value)}
                                />
                            </div>
                        </div>,
                        <div className="row mt-4">
                            <div className="col-md-6 d-flex gap-2 flex-wrap">
                                {allAmenities.map((item, index) => {
                                    return (
                                        <CheckBox
                                            label={item.title}
                                            key={index}
                                            checked={item.isSelected}
                                            onChnage={() =>
                                                setAllAmenities((state) => {
                                                    state[index].isSelected =
                                                        !item.isSelected;

                                                    return JSON.parse(
                                                        JSON.stringify(state)
                                                    );
                                                })
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </div>,
                        <div className="row mt-4">
                            <ImageInput
                                title="Upload Hotel Image"
                                value={image}
                                onUploadComplete={(url) => setImage(url)}
                                allowedTypes={['image/*']}
                            />
                        </div>
                    ]}
                    onSubmit={onSubmit}
                />
            </BodyCard>
        </PageBody>
    );
};

export default CreateHomestay;
