import { Res } from '../DataTypes/Global';
import { UserApi } from '../Utils/Apis';
import {
    DashboardCountType,
    DashboardEventType,
    PartnerLoginCount,
    RSSFeedDataType
} from '../DataTypes/Dashboard';

async function getDashboardCount(): Promise<Res<DashboardCountType>> {
    let endpoint = 'dashboard';
    return UserApi.get(endpoint);
}

async function getDashboardEvents(): Promise<Res<Array<DashboardEventType>>> {
    let endpoint = 'upcoming-events';
    return UserApi.get(endpoint);
}

async function getPartnerLoginCount(): Promise<Res<PartnerLoginCount>> {
    let endpoint = 'track-login/count';
    return UserApi.get(endpoint);
}

async function getAllRSSFeed(): Promise<Res<Array<RSSFeedDataType>>> {
    let endpoint = 'rss';
    return UserApi.get(endpoint);
}

const DashboardService = {
    getDashboardCount,
    getDashboardEvents,
    getPartnerLoginCount,
    getAllRSSFeed
};

export default DashboardService;
