import React, { useState } from 'react';

type Props = {
    title: string;
    children?: React.ReactNode;
    className?: string;
    titleStyle?: React.CSSProperties;
    isOpened: boolean;
    onToggle: (val: boolean) => void;
};
const AccordianCard: React.FC<Props> = ({
    title,
    children,
    className,
    titleStyle,
    isOpened,
    onToggle
}) => {
    return (
        <div className={`card accordian-card ${className}`}>
            <div
                className="card-header"
                onClick={() => onToggle(!isOpened)}
            >
                <div className="row align-items-center">
                    <div className="col-11">
                        <h6
                            className="card-title"
                            style={titleStyle}
                        >
                            {title}
                        </h6>
                    </div>

                    <div className="col-1">
                        <i className={`fa fa-chevron-${isOpened ? 'down' : 'right'}`}></i>
                    </div>
                </div>
            </div>

            {isOpened ? <div className="card-body pt-0">{children}</div> : null}
        </div>
    );
};

export default AccordianCard;
