import { createContext } from 'react';

export type ToastType = 'success' | 'danger' | 'primary';
export type Toast = {
    id: number;
    message: string;
    type?: ToastType;
};

type ToastContextType = {
    toasts: Array<Toast>;
    show: (message: string, type?: ToastType, autoHide?: boolean, timeout?: number) => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export default ToastContext;
