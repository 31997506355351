import React, { RefObject, useRef } from 'react';

type Props = {
    value: string;
    onChange: (val: string) => void;
    size?: number;
};

const ColorInput: React.FC<Props> = ({ value, onChange, size = 50 }) => {
    const ref = useRef<HTMLInputElement>(null);

    return (
        <div className="color-input">
            <div
                style={{
                    height: size,
                    width: size,
                    borderRadius: 8,
                    backgroundColor: value
                }}
                onClick={() => ref.current?.click()}
            />
            <input
                ref={ref}
                type="color"
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
};

export default ColorInput;
