import React, {
    InputHTMLAttributes,
    forwardRef,
    useImperativeHandle,
    useState
} from 'react';

export type CustomeInputRef = {
    validate: () => boolean;
};

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    errorMessage?: string;
    value: string;
    checkValid?: (value: string) => boolean;
    leftIcon?: React.ReactNode | string;
    rightIcon?: React.ReactNode | string;
    onRightIconClick?: () => void;
    layoutHorizontal?: boolean;
    containerClass?: string;
    uppercase?: boolean;
}

const Input = forwardRef<CustomeInputRef, InputProps>(
    (
        {
            label,
            errorMessage,
            value,
            onChange = () => {},
            checkValid,
            leftIcon,
            rightIcon,
            onRightIconClick,
            layoutHorizontal,
            containerClass,
            uppercase = false,
            ...props
        },
        ref
    ) => {
        const [isError, setIsError] = useState<boolean>(false);
        const [isValid, setIsValid] = useState<boolean>(false);

        useImperativeHandle(
            ref,
            () => {
                return {
                    validate() {
                        let validRequired = value !== '' && props.required ? true : false;
                        let customeCheckValid = checkValid ? checkValid(value) : true;

                        setIsError(!(validRequired && customeCheckValid));
                        setIsValid(validRequired && customeCheckValid);

                        return validRequired && customeCheckValid;
                    }
                };
            },
            [value, props.required, checkValid]
        );

        return (
            <div className={`mb-2 ${containerClass}`}>
                {layoutHorizontal ? (
                    <div className="form-group row">
                        <label
                            className={`col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label ${
                                uppercase ? 'text-uppercase' : ''
                            }`}
                        >
                            {label}
                        </label>
                        <div className="col-lg-9 col-xl-8">
                            <div className="input-group">
                                {leftIcon ? (
                                    <div className="input-group-text">{leftIcon}</div>
                                ) : null}

                                <input
                                    {...props}
                                    className={`form-control ${props.className} ${
                                        isError ? 'error' : ''
                                    } ${isValid ? 'success' : ''}`}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />

                                {rightIcon ? (
                                    <button
                                        onClick={onRightIconClick}
                                        className="input-group-text"
                                        type="button"
                                    >
                                        {rightIcon}
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="form-group">
                        {label ? (
                            <label
                                className={`form-label ${
                                    uppercase ? 'text-uppercase' : ''
                                }`}
                            >
                                {label}
                            </label>
                        ) : null}

                        <div className="input-group">
                            {leftIcon ? (
                                <div className="input-group-text">{leftIcon}</div>
                            ) : null}

                            <input
                                {...props}
                                className={`form-control ${
                                    uppercase ? 'text-uppercase' : ''
                                } ${props.className} ${isError ? 'error' : ''} ${
                                    isValid ? 'success' : ''
                                }`}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                            />

                            {rightIcon ? (
                                <button
                                    onClick={onRightIconClick}
                                    className="input-group-text"
                                    type="button"
                                >
                                    {rightIcon}
                                </button>
                            ) : null}
                        </div>
                    </div>
                )}

                <small
                    className={`mt-1 ${
                        layoutHorizontal ? 'col-lg-9 col-xl-8 offset-lg-3' : ''
                    }`}
                    style={{
                        display: isError ? 'block' : 'none',
                        color: '#ef4d56'
                    }}
                >
                    {errorMessage ?? `${label} is Required.`}
                </small>
            </div>
        );
    }
);

export default Input;
