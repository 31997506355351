import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import './DatatableStyle.css';
import Dropdown from '../Inputs/Dropdown';
import Input from '../Inputs/Input';
import { PaginationControl } from 'react-bootstrap-pagination-control';

export interface DataTableThead {
    title: any;
    style?: CSSProperties;
    className?: string;
    slug: string;
    hide?: boolean;
}

interface DataTableProps<TBodyData> {
    thead: DataTableThead[];
    tbody: TBodyData[];
    makeTableData: (item: TBodyData, index: number) => Record<string, any>;
    upperCase?: boolean;
    defaultItemShowCount?: number
}

const entriesCountData = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
];

const Datatable = <T extends Record<string, any>>({
    thead,
    tbody,
    makeTableData,
    upperCase = false,
    defaultItemShowCount = 10
}: DataTableProps<T>) => {
    const [entriesCount, setEntriesCount] = useState<number>(defaultItemShowCount);
    const [bodyData, setBodyData] = useState<Array<Record<string, any>>>([]);
    const [backupBodyData, setBackupBodyData] = useState<Array<Record<string, any>>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [search, setSearch] = useState<string>('');
    const [isSearchEnable, setIsSearchEnable] = useState<boolean>(false);

    useEffect(() => {
        let data = tbody.reduce((prev, current, currentIndex) => {
            let newItem = makeTableData(current, currentIndex);

            return [...prev, newItem];
        }, new Array<Record<string, any>>());

        setBodyData(data);
        setBackupBodyData(data);
    }, [tbody]);

    useEffect(() => {
        if (isSearchEnable) {
            if (search.trim() != '') {
                const regex = new RegExp(search.trim(), 'i');

                const searchData = backupBodyData.filter((item) => {
                    return Object.values(item).some((value) =>
                        regex.test(value.toString())
                    );
                });

                setBodyData(searchData);
            } else {
                setBodyData(backupBodyData);
            }
        } else {
            setIsSearchEnable(true);
        }
    }, [search]);

    const startEntry = (currentPage - 1) * entriesCount + 1;
    const endEntry = Math.min(currentPage * entriesCount, bodyData.length);

    return (
        <div style={{ overflow: 'scroll' }}>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <Dropdown
                        options={entriesCountData}
                        value={entriesCount}
                        onChange={(e) => setEntriesCount(Number(e.target.value))}
                    />
                    <span className="mb-2 ms-1 table-secondary-text">
                        entries per page
                    </span>
                </div>

                <div className="d-flex align-items-center">
                    <span className="mb-2 me-1 table-secondary-text">Search:</span>
                    <Input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        uppercase={false}
                    />
                </div>
            </div>
            <div className="custom-datatable">
                <div className="custom-tb-list custom-tb-header">
                    {thead.map((item, index) => {
                        if (item.hide) return;
                        return (
                            <div
                                key={index}
                                className="custome-tb-item"
                            >
                                {item.title}
                            </div>
                        );
                    })}
                </div>

                {bodyData.map((item, index) => {
                    if (index >= startEntry - 1 && index <= endEntry - 1) {
                        return (
                            <div
                                className="custom-tb-list custom-tb-body"
                                key={index}
                            >
                                {thead.map((it, key) => {
                                    if (it.hide) return;

                                    return (
                                        <div
                                            key={key}
                                            className={`custome-tb-item ${
                                                upperCase ? 'text-uppercase' : ''
                                            }`}
                                        >
                                            {item[it.slug]}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }
                })}
            </div>

            <div className="d-flex justify-content-between align-items-center pagination-control mt-2">
                <span>
                    Showing {startEntry} to {endEntry} of {bodyData.length} entries
                </span>

                <PaginationControl
                    page={currentPage}
                    between={3}
                    total={bodyData.length}
                    limit={entriesCount}
                    changePage={(page) => setCurrentPage(page)}
                    ellipsis={1}
                    last={true}
                />
            </div>
        </div>
    );
};

export default Datatable;
