import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Input, { CustomeInputRef } from './Input';

interface DropdownProps {
    label?: string;
    errorMessage?: string;
    value: Array<string | number>;
    checkValid?: (value: Array<string | number>) => boolean;
    leftIcon?: React.ReactNode | string;
    rightIcon?: React.ReactNode | string;
    onRightIconClick?: () => void;
    placeholder?: string;
    options: Array<Record<string, any>>;
    valueKey?: string;
    labelKey?: string;
    onChange?: (values: Array<string | number>) => void;
    required?: boolean;
    className?: string;
    uppercase?: boolean;
    isSearch?: boolean;
}

const MultiSelectDropdown = forwardRef<CustomeInputRef, DropdownProps>(
    (
        {
            label,
            errorMessage,
            value,
            onChange = () => {},
            checkValid,
            leftIcon,
            rightIcon,
            onRightIconClick,
            placeholder = 'Select items',
            options,
            valueKey = 'value',
            labelKey = 'label',
            required,
            className,
            uppercase = true,
            isSearch = false
        },
        ref
    ) => {
        const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
        const [isError, setIsError] = useState<boolean>(false);
        const [isValid, setIsValid] = useState<boolean>(false);
        const [search, setSearch] = useState<string>('');

        useImperativeHandle(
            ref,
            () => {
                return {
                    validate() {
                        let validRequired = value.length > 0 && required ? true : false;
                        let customeCheckValid = checkValid ? checkValid(value) : true;

                        setIsError(!(validRequired && customeCheckValid));
                        setIsValid(validRequired && customeCheckValid);

                        return validRequired && customeCheckValid;
                    }
                };
            },
            [value, required, checkValid]
        );

        const handleToggleDropdown = () => {
            setIsDropdownOpen(!isDropdownOpen);
        };

        return (
            <div className="mb-2">
                <label className={`form-label ${uppercase ? 'text-uppercase' : ''}`}>
                    {label}
                </label>
                <div className="input-group">
                    {leftIcon ? <div className="input-group-text">{leftIcon}</div> : null}

                    <div
                        className={`form-select ${className} ${isError ? 'error' : ''} ${
                            isValid ? 'success' : ''
                        }`}
                    >
                        <div
                            className="d-flex justify-content-between align-items-center user-select-none"
                            onClick={handleToggleDropdown}
                        >
                            {value.length > 0
                                ? options
                                      .reduce<Array<string>>((prev, current) => {
                                          if (
                                              value.findIndex((it) => {
                                                  return (
                                                      it.toString().trim() ===
                                                      current[valueKey].trim()
                                                  );
                                              }) >= 0
                                          ) {
                                              return [...prev, current[labelKey]];
                                          }

                                          return prev;
                                      }, new Array<string>())
                                      .join(', ')
                                : placeholder}
                        </div>
                    </div>

                    {rightIcon ? (
                        <button
                            onClick={onRightIconClick}
                            className="input-group-text"
                            type="button"
                        >
                            {rightIcon}
                        </button>
                    ) : null}
                    {isDropdownOpen && (
                        <div className="multiple-select-dropdown-option-container">
                            {isSearch ? (
                                <div className="search-container">
                                    <Input
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder="Search Here"
                                        required
                                        leftIcon={
                                            <i className="las la-search text-secondary fs-18 align-middle"></i>
                                        }
                                        containerClass="mb-0"
                                    />
                                </div>
                            ) : null}

                            <div className="option-container">
                                <ul className="list-unstyled mb-0">
                                    {options
                                        .filter((it) =>
                                            it[labelKey]
                                                .toLowerCase()
                                                .includes(search.toLowerCase())
                                        )
                                        .map((item, index) => (
                                            <li key={index}>
                                                <div
                                                    className="d-flex align-items-center options user-select-none"
                                                    onClick={() => {
                                                        let data: Array<string | number> =
                                                            JSON.parse(
                                                                JSON.stringify(value)
                                                            );
                                                        if (
                                                            data.findIndex((it) => {
                                                                return (
                                                                    it
                                                                        .toString()
                                                                        .trim() ===
                                                                    item[valueKey].trim()
                                                                );
                                                            }) >= 0
                                                        ) {
                                                            onChange(
                                                                data.filter(
                                                                    (it) =>
                                                                        it
                                                                            .toString()
                                                                            .trim() !==
                                                                        item[valueKey]
                                                                )
                                                            );
                                                        } else {
                                                            data.push(item[valueKey]);
                                                            onChange(data);
                                                        }
                                                    }}
                                                >
                                                    <i
                                                        className="fas fa-check me-2"
                                                        style={{
                                                            display:
                                                                value.findIndex((it) => {
                                                                    return (
                                                                        it
                                                                            .toString()
                                                                            .trim() ===
                                                                        item[
                                                                            valueKey
                                                                        ].trim()
                                                                    );
                                                                }) >= 0
                                                                    ? 'block'
                                                                    : 'none',
                                                            color: '#22c5ad'
                                                        }}
                                                    ></i>
                                                    {item[labelKey]}
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>

                <small
                    className="mt-1"
                    style={{
                        display: isError ? 'block' : 'none',
                        color: '#ef4d56'
                    }}
                >
                    {errorMessage ?? `${label} is Required.`}
                </small>
            </div>
        );
    }
);

export default MultiSelectDropdown;
