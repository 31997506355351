import React from 'react';

type Props = {
    title: string;
    children?: React.ReactNode;
    headerRightContent?: React.ReactNode;
};

const BodyCard: React.FC<Props> = ({ title, children, headerRightContent }) => {
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col">
                            <h4 className="card-title">{title}</h4>
                        </div>

                        <div className="col-auto">
                            {headerRightContent}
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">{children}</div>
            </div>
        </div>
    );
};

export default BodyCard;
