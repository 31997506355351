import React, {
    forwardRef,
    InputHTMLAttributes,
    TextareaHTMLAttributes,
    useImperativeHandle,
    useState
} from 'react';
import { CustomeInputRef } from './Input';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    errorMessage?: string;
    value: string;
    checkValid?: (value: string) => boolean;
    containerClass?: string;
    uppercase?: boolean;
    rightIcon?: React.ReactNode | string;
    onRightIconClick?: () => void;
}

const Textarea = forwardRef<CustomeInputRef, TextAreaProps>(
    (
        {
            label,
            errorMessage,
            value,
            onChange = () => {},
            checkValid,
            containerClass,
            rightIcon,
            onRightIconClick,
            uppercase = true,
            ...props
        },
        ref
    ) => {
        const [isError, setIsError] = useState<boolean>(false);
        const [isValid, setIsValid] = useState<boolean>(false);

        useImperativeHandle(
            ref,
            () => {
                return {
                    validate() {
                        let validRequired = value !== '' && props.required ? true : false;
                        let customeCheckValid = checkValid ? checkValid(value) : true;

                        setIsError(!(validRequired && customeCheckValid));
                        setIsValid(validRequired && customeCheckValid);

                        return validRequired && customeCheckValid;
                    }
                };
            },
            [value, props.required, checkValid]
        );

        return (
            <div className={`mb-2 ${containerClass}`}>
                <div className="form-group">
                    {label ? (
                        <label
                            className={`form-label ${uppercase ? 'text-uppercase' : ''}`}
                        >
                            {label}
                        </label>
                    ) : null}

                    <div className="input-group">
                        <textarea
                            {...props}
                            className={`form-control ${
                                uppercase ? 'text-uppercase' : ''
                            } ${props.className} ${isError ? 'error' : ''} ${
                                isValid ? 'success' : ''
                            }`}
                            value={value}
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />

                        {rightIcon ? (
                            <button
                                onClick={onRightIconClick}
                                className="input-group-text"
                                type="button"
                            >
                                {rightIcon}
                            </button>
                        ) : null}
                    </div>
                </div>

                <small
                    className={`mt-1`}
                    style={{
                        display: isError ? 'block' : 'none',
                        color: '#ef4d56'
                    }}
                >
                    {errorMessage ?? `${label} is Required.`}
                </small>
            </div>
        );
    }
);

export default Textarea;
