import React, { useEffect, useState } from 'react';
import PageBody from '../../Components/UI/PageBody';
import Card from '../../Components/UI/Card';
import Input from '../../Components/Inputs/Input';
import { useParams } from 'react-router-dom';
import CustomerService from '../../Services/CustomerService';
import { CustomerBuisnessProfile } from '../../DataTypes/Customer';
import CommonService from '../../Services/CommonService';
import { CityDataType, StatesDataType } from '../../DataTypes/Global';
import Dropdown from '../../Components/Inputs/Dropdown';

const BuisnessProfile = () => {
    const { id } = useParams();

    const [buisnessProfile, setBuisnessProfile] =
        useState<CustomerBuisnessProfile | null>(null);
    const [allState, setAllState] = useState<Array<StatesDataType>>([]);
    const [allCity, setAllCity] = useState<Array<CityDataType>>([]);

    const [logo, setLogo] = useState<string>('');
    const [businessName, setBusinessName] = useState<string>('');
    const [buildingName, setBuildingName] = useState<string>('');
    const [area, setArea] = useState<string>('');
    const [pinCode, setPinCode] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [gst, setGst] = useState<string>('');
    const [mobileNo, setMobileNo] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [domain, setDomain] = useState<string>('');

    useEffect(() => {
        getAllState();
        getBuisnessProfile();
    }, []);

    useEffect(() => {
        if (allState.length > 0 && state != '--' && state != '') {
            const stateId = allState.find((it) => it.name === state)?._id;
            getAllCity(stateId ?? '');
        }
    }, [allState, state]);

    const getBuisnessProfile = () => {
        CustomerService.getCustomerBuisnessProfile(id ?? '')
            .then((result) => {
                if (result && result.status) {
                    setBuisnessProfile(result.data);
                    setLogo(result.data.logo ?? '');
                    setBusinessName(result.data.name ?? '');
                    setBuildingName(result.data.address?.buildingName ?? '');
                    setArea(result.data.address?.areaOrLocality ?? '');
                    setPinCode(result.data.address?.pinCode ?? '');
                    setState(result.data.address?.state ?? '');
                    setCity(result.data.address?.city ?? '');
                    setGst(result.data.gst ?? '');
                    setMobileNo(result.data.phone);
                    setEmail(result.data.email);
                    setDomain(result.data.website ?? '');
                }
            })
            .catch((error) => console.log('error', error));
    };

    const getAllState = () => {
        CommonService.getAllStates()
            .then((result) => {
                if (result && result.status) {
                    setAllState(result.data);
                }
            })
            .catch((error) => console.log('error', error));
    };

    const getAllCity = (stateId: string) => {
        CommonService.getAllCity(stateId ?? '')
            .then((result) => {
                if (result && result.status) {
                    setAllCity(result.data);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    return (
        <PageBody>
            <div className="buisness-grid-container">
                <Card
                    className="buisness-logo mb-0"
                    title="Buisness Logo"
                >
                    <div className="d-flex align-items-center">
                        <div
                            style={{
                                height: 70,
                                width: 70,
                                borderWidth: 1,
                                borderRadius: 10,
                                borderColor: '#666',
                                borderStyle: 'dashed'
                            }}
                        >
                            <img
                                src={
                                    logo === '--'
                                        ? require('../../assets/images/icons/image.png')
                                        : logo
                                }
                                style={{ height: 69, width: 69, objectFit: 'contain' }}
                            />
                        </div>

                        <div className="ms-3">
                            <h5>Change Logo</h5>
                            <h6 className="mb-0">File Format: JPEG, WEBP, PNG</h6>
                        </div>
                    </div>

                    <button
                        className="btn btn-primary float-end mt-2"
                        type="button"
                    >
                        Submit
                    </button>
                </Card>
                <Card
                    className="buisness-info mb-0"
                    title="Business Info"
                >
                    <Input
                        label="Business Name"
                        value={businessName}
                        leftIcon={<i className="las la-building"></i>}
                        onChange={(e) => setBusinessName(e.target.value)}
                    />
                    <Input
                        label="Building Name"
                        value={buildingName}
                        leftIcon={<i className="las la-map-marker"></i>}
                        onChange={(e) => setBuildingName(e.target.value)}
                    />

                    <Input
                        label="Area/Location"
                        value={area}
                        leftIcon={<i className="las la-map-marker"></i>}
                        onChange={(e) => setArea(e.target.value)}
                    />

                    <Input
                        label="Pin Code"
                        value={pinCode}
                        leftIcon={<i className="las la-map-marker"></i>}
                        onChange={(e) => setPinCode(e.target.value)}
                    />

                    {allState.length > 0 ? (
                        <Dropdown
                            label="State"
                            value={state}
                            leftIcon={<i className="las la-map-marker"></i>}
                            options={allState}
                            labelKey="name"
                            valueKey="name"
                            onChange={(e) => setState(e.target.value)}
                        />
                    ) : null}

                    {allCity.length > 0 ? (
                        <Dropdown
                            label="City"
                            value={city}
                            leftIcon={<i className="las la-map-marker"></i>}
                            options={allCity}
                            labelKey="name"
                            valueKey="name"
                            onChange={(e) => setCity(e.target.value)}
                        />
                    ) : null}

                    <button
                        className="btn btn-primary float-end mt-2"
                        type="button"
                    >
                        Submit
                    </button>
                </Card>
                <Card
                    className="buisness-gst mb-0"
                    title="GST Info"
                >
                    <Input
                        label="GST Number"
                        value={gst}
                        leftIcon={<i className="lab la-slack"></i>}
                        onChange={(e) => setGst(e.target.value)}
                    />

                    <button
                        className="btn btn-primary float-end mt-2"
                        type="button"
                    >
                        Submit
                    </button>
                </Card>
                <Card
                    className="buisness-communication mb-0"
                    title="Communication"
                >
                    <Input
                        label="Business Mobile Number"
                        value={mobileNo}
                        leftIcon={<i className="las la-phone"></i>}
                        onChange={(e) => setMobileNo(e.target.value)}
                    />
                    <Input
                        label="Business Email"
                        value={email}
                        leftIcon={<i className="las la-envelope"></i>}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <button
                        className="btn btn-primary float-end mt-2"
                        type="button"
                    >
                        Submit
                    </button>
                </Card>
                <Card
                    className="buisness-domain-config mb-0"
                    title="Domain Configuration"
                >
                    <Input
                        label="Domain"
                        value={domain.split('.')[0]}
                        leftIcon={<i className="las la-globe"></i>}
                        rightIcon={<h6 className="mb-0">.homstay.in</h6>}
                        onChange={(e) => setDomain(e.target.value)}
                    />

                    <button
                        className="btn btn-primary float-end mt-2"
                        type="button"
                    >
                        Submit
                    </button>
                </Card>
            </div>
        </PageBody>
    );
};

export default BuisnessProfile;
