import React, { useEffect } from 'react';
import { RouteList } from '../Route';
import { RouteData } from '../DataTypes/Global';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthStore from '../Zustand/Auth';
import AuthService from '../Services/AuthServices';

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { isLoggedIn, setUserData } = useAuthStore();

    useEffect(() => {
        if (isLoggedIn) {
            AuthService.getProfile()
                .then((result) => {
                    if (result && result.status) {
                        setUserData(result.data);
                    }
                })
                .catch((error: any) => {
                    console.log('error', error);
                });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        const currentUrl = window.location.href.split(/[?#]/)[0];
        const navLinks = document.querySelectorAll<HTMLAnchorElement>('.navbar-nav a');

        navLinks.forEach((link) => {
            const parentElement = link.parentElement;
            let parentCollapse = link.closest('.collapse') as HTMLElement | null;

            if (link.href === currentUrl) {
                link.classList.add('active');
                parentElement?.classList.add('active');

                while (parentCollapse) {
                    parentCollapse.classList.add('show');
                    const parentButton = parentCollapse.parentElement
                        ?.children[0] as HTMLElement;
                    if (parentButton) {
                        parentButton.classList.add('active');
                        parentButton.setAttribute('aria-expanded', 'true');
                    }
                    parentCollapse = parentCollapse.parentElement?.closest(
                        '.collapse'
                    ) as HTMLElement | null;
                }
            } else {
                link.classList.remove('active');
                parentElement?.classList.remove('active');
            }
        });
    }, [location]);

    const makeSidebarItems = (item: RouteData): React.ReactElement => {
        return (
            <>
                <a
                    className="nav-link"
                    href={
                        item.hasChildren
                            ? `#sidebar${item.name.split(' ').join('_')}`
                            : item.path
                    }
                    data-bs-toggle={item.hasChildren && !item.noncollapsable ? 'collapse' : undefined}
                    role={item.hasChildren && !item.noncollapsable ? 'button' : undefined}
                    aria-expanded={
                        item.hasChildren && !item.noncollapsable ? 'false' : undefined
                    }
                    aria-controls={
                        item.hasChildren && !item.noncollapsable
                            ? `#sidebar${item.name.split(' ').join('_')}`
                            : undefined
                    }
                    defaultValue={item.path}
                    onClick={(e) => {
                        if (!item.hasChildren) {
                            e.preventDefault();
                            navigate(item.path);
                        }
                    }}
                >
                    {item.icon ? <i className={`${item.icon} menu-icon`}></i> : null}

                    <span>{item.name}</span>
                </a>

                {item.hasChildren ? (
                    <div
                        className={`collapse ${item.noncollapsable ? 'show' : ''}`}
                        id={`sidebar${item.name.split(' ').join('_')}`}
                    >
                        <ul className="nav flex-column">
                            {item.children
                                .filter((it) => !it.hideOnSidebar)
                                .map((it, index) => {
                                    return (
                                        <li
                                            className="nav-item"
                                            key={index}
                                        >
                                            {makeSidebarItems(it)}
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                ) : null}
            </>
        );
    };
    return (
        <>
            <div className="startbar d-print-none">
                <div className="brand">
                    <a
                        href="/"
                        className="logo"
                    >
                        {/* <span>
                            <img
                                src={require('../assets/images/logo-sm.png')}
                                alt="logo-small"
                                className="logo-sm"
                            />
                        </span> */}
                        <span className="">
                            <img
                                src={require('../assets/images/logo.png')}
                                alt="logo-large"
                                className="logo-light logo-sm sidebar-logo-large"
                            />
                            <img
                                src={require('../assets/images/logo-light.png')}
                                alt="logo-large"
                                className="logo-sm logo-dark sidebar-logo-large"
                            />

                            <img
                                src={require('../assets/images/fab-dark.png')}
                                alt="logo-large"
                                className="logo-light logo-sm sidebar-logo-small"
                            />
                            <img
                                src={require('../assets/images/fav-light.png')}
                                alt="logo-large"
                                className="logo-sm logo-dark sidebar-logo-small"
                            />
                        </span>
                    </a>
                </div>

                <div className="startbar-menu">
                    <div
                        className="startbar-collapse"
                        id="startbarCollapse"
                        data-simplebar
                    >
                        <div className="d-flex align-items-start flex-column w-100">
                            <ul className="navbar-nav mb-auto w-100">
                                <li className="menu-label pt-0 mt-0">
                                    <small className="label-border">
                                        <div className="border_left hidden-xs"></div>
                                        <div className="border_right"></div>
                                    </small>
                                    <span>Main Menu</span>
                                </li>

                                {RouteList.filter((it) => !it.hideOnSidebar).map(
                                    (item, index) => {
                                        return (
                                            <li
                                                className="nav-item"
                                                key={index}
                                            >
                                                {makeSidebarItems(item)}
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="startbar-overlay d-print-none"></div>
        </>
    );
};

export default Sidebar;
