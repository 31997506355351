import React, { useState } from 'react';
import PageBody from '../../Components/UI/PageBody';
import Card from '../../Components/UI/Card';
import './Website.css';
import AccordianCard from '../../Components/UI/AccordianCard';
import Input from '../../Components/Inputs/Input';
import ColorInput from '../../Components/Inputs/ColorInput';
import Textarea from '../../Components/Inputs/Textarea';
import MultiSelectDropdown from '../../Components/Inputs/MultiSelectDropdown';

const Website = () => {
    const [isBannerSectionOpen, setIsBannerSectionOpen] = useState<boolean>(true);
    const [isRoomSectionOpen, setIsRoomSectionOpen] = useState<boolean>(false);
    const [isSiteConfigSectionOpen, setIsSiteConfigSectionOpen] =
        useState<boolean>(false);
    const [bannerKeyword, setBannerKeyword] = useState<Array<number>>([1, 2, 3]);
    return (
        <PageBody>
            <div className="col-6">
                <AccordianCard
                    title="Website Banner"
                    isOpened={isBannerSectionOpen}
                    onToggle={setIsBannerSectionOpen}
                >
                    <div className="d-flex align-items-center">
                        <div
                            style={{
                                height: 70,
                                width: 70,
                                borderWidth: 1,
                                borderRadius: 10,
                                borderColor: '#666',
                                borderStyle: 'dashed'
                            }}
                        >
                            <img
                                src={require('../../assets/images/icons/image.png')}
                                style={{ height: 69, width: 69, objectFit: 'cover' }}
                            />
                        </div>

                        <div className="ms-3">
                            <h5>Change Banner</h5>
                            <h6 className="mb-0">File Format: JPEG, WEBP, PNG</h6>
                        </div>
                    </div>

                    <Input
                        containerClass="mt-3"
                        value="Welcome to Travelers Friend"
                        label="Banner Title"
                        rightIcon={
                            <ColorInput
                                size={30}
                                value="#000"
                                onChange={() => {}}
                            />
                        }
                    />
                    {bannerKeyword.map((item, index) => {
                        return (
                            <div
                                className="d-flex align-items-end"
                                key={index}
                            >
                                <Input
                                    containerClass="flex-grow-1"
                                    value="Welcome to Travelers Friend"
                                    label={`Keyword ${index + 1}`}
                                    rightIcon={
                                        <ColorInput
                                            size={30}
                                            value="#000"
                                            onChange={() => {}}
                                        />
                                    }
                                />
                                {bannerKeyword.length - 1 === index ? (
                                    <button className="btn btn-blue mb-2 ms-2">+</button>
                                ) : null}

                                {index !== 0 || bannerKeyword.length - 1 !== index ? (
                                    <button className="btn btn-danger mb-2 ms-2">
                                        -
                                    </button>
                                ) : null}
                            </div>
                        );
                    })}
                </AccordianCard>

                <AccordianCard
                    title="Website Rooms Section"
                    isOpened={isRoomSectionOpen}
                    onToggle={setIsRoomSectionOpen}
                >
                    <Input
                        containerClass="mt-3"
                        value="Discover our featured rooms"
                        label="Title"
                        rightIcon={
                            <ColorInput
                                size={30}
                                value="#000"
                                onChange={() => {}}
                            />
                        }
                    />

                    <Textarea
                        containerClass="mt-3"
                        value="Discover our featured rooms"
                        label="Title"
                        rightIcon={
                            <ColorInput
                                size={30}
                                value="#000"
                                onChange={() => {}}
                            />
                        }
                    />

                    <MultiSelectDropdown
                        label="Rooms"
                        options={[
                            { label: 'Room 101', value: '101' },
                            { label: 'Room 102', value: '102' }
                        ]}
                        value={[]}
                    />
                </AccordianCard>

                <AccordianCard
                    title="Site Configuration"
                    isOpened={isSiteConfigSectionOpen}
                    onToggle={setIsSiteConfigSectionOpen}
                >
                    <div className="d-flex align-items-center">
                        <div
                            style={{
                                height: 70,
                                width: 70,
                                borderWidth: 1,
                                borderRadius: 10,
                                borderColor: '#666',
                                borderStyle: 'dashed'
                            }}
                        >
                            <img
                                src={require('../../assets/images/icons/image.png')}
                                style={{ height: 69, width: 69, objectFit: 'cover' }}
                            />
                        </div>

                        <div className="ms-3">
                            <h5>Change Fav Icon</h5>
                            <h6 className="mb-0">File Format: JPEG, PNG, ICO</h6>
                        </div>
                    </div>

                    <div className="d-flex align-items-center mt-3">
                        <ColorInput
                            size={50}
                            value="#000"
                            onChange={() => {}}
                        />

                        <div className="ms-3">
                            <h5>Theme Color</h5>
                            <h6 className="mb-0">#000000</h6>
                        </div>
                    </div>

                    <div className="d-flex align-items-center mt-3">
                        <ColorInput
                            size={50}
                            value="#000"
                            onChange={() => {}}
                        />

                        <div className="ms-3">
                            <h5>Footer Color</h5>
                            <h6 className="mb-0">#000000</h6>
                        </div>
                    </div>

                    <Input
                        containerClass="mt-3"
                        value="Truvadi"
                        label="Meta Title"
                    />

                    <Textarea
                        containerClass="mt-3"
                        value="Truvadi Brings you personally travel experiences, from peaceful beach gateways to exciting City Adventures. With exclusive offers and expert tips, we make it easy to plan memorable trips that suit your style."
                        label="Meta Description"
                    />
                </AccordianCard>
            </div>
            <div className="col-6">
                {/* <Card title="Preview"> */}
                <div className="browser-container">
                    <div className="browser-header">
                        <div className="header-left" />

                        <div className="tab-container">
                            <div className="tab">
                                <img src="https://img.icons8.com/windows/100/america.png" />

                                <h6 className="mb-0 ms-2">Truvadi</h6>
                            </div>
                        </div>

                        <div className="header-right" />
                    </div>

                    <div className="browser-body">
                        <div className="searchbar-container">
                            <i className="fa fa-arrow-left active-font"></i>

                            <i className="fa fa-arrow-right deactive-font"></i>

                            <i className="fa fa-rotate-right active-font"></i>

                            <div className="search-box">
                                <div className="circle">
                                    <i className="fa fa-lock active-font fs-11"></i>
                                </div>

                                <h6 className="mb-0 ms-1">sundartravels.homestay.in</h6>
                            </div>

                            <img src="https://phantom-marca.unidadeditorial.es/9279e8039b9040209f07a16f0201dd63/resize/828/f/jpg/assets/multimedia/imagenes/2023/01/12/16735402991293.jpg" />

                            <i className="fa fa-ellipsis-vertical active-font"></i>
                        </div>

                        <div className="body">
                            <div className="banner-section">
                                <div className="content">
                                    <h3 className="mb-3">Welcome to Travelers Friend</h3>

                                    <div className="keyword">
                                        <i className="fa-regular fa-square-check"></i>
                                        <h6 className="mb-0 ms-1">
                                            Stay, Relax and Explore
                                        </h6>
                                    </div>

                                    <div className="keyword">
                                        <i className="fa-regular fa-square-check"></i>
                                        <h6 className="mb-0 ms-1">
                                            Your Home Away from Home
                                        </h6>
                                    </div>

                                    <div className="keyword">
                                        <i className="fa-regular fa-square-check"></i>
                                        <h6 className="mb-0 ms-1">
                                            Discover Authentic Local Living
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div className="room-section py-3">
                                <h3 className="mb-0">Discover Our Featured Rooms</h3>
                                <img
                                    className="mt-1"
                                    src={require('../../assets/images/icons/wave.png')}
                                />

                                <p className="mx-3 mt-2">
                                    Our featured rooms combine comfort, style and
                                    convenience, handpicked to ensure a wonderful stay.
                                    Weather you are here to relax or explore, find the
                                    perfect space to call your own during your journey.
                                </p>

                                <div className="d-flex gap-2">
                                    <div className="card">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/homstay-c1fd5.appspot.com/o/test%2Froom%2F4981376c-1d6f-45f9-935e-08a7d85d82a8.jpg?alt=media&token=05f52e33-01a0-4f43-8aae-0eb49123d098" />

                                        <div className="d-flex align-items-center justify-content-between p-1 footer">
                                            <h6 className="mb-0">Single Bed</h6>

                                            <button>View Details</button>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/homstay-c1fd5.appspot.com/o/test%2Froom%2F184a6a70-c3fd-465a-bc79-230564c3f2b6.jpg?alt=media&token=741479f4-e806-4e89-9f44-a20eff5a90e8" />

                                        <div className="d-flex align-items-center justify-content-between p-1 footer">
                                            <h6 className="mb-0">Double Room</h6>

                                            <button>View Details</button>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/homstay-c1fd5.appspot.com/o/test%2Froom%2F66480e69-5c45-4dc3-a4d5-5693889aa8e1.jpg?alt=media&token=51905353-848d-4dac-9c84-e842b6041e94" />

                                        <div className="d-flex align-items-center justify-content-between p-1 footer">
                                            <h6 className="mb-0">Triple Room</h6>

                                            <button>View Details</button>
                                        </div>
                                    </div>
                                </div>

                                <button className="">View Room List</button>
                            </div>

                            <div className="gallery-section py-3">
                                <h3 className="mb-0">Explore our space</h3>
                                <img
                                    className="mt-1"
                                    src={require('../../assets/images/icons/wave.png')}
                                />

                                <p className="mx-3 mt-2">
                                    Browse our gallery to see what makes our homestay
                                    special. Each photo tells a story of comfort,
                                    relaxation and unforgettable moments. Find inspiration
                                    for your next adventure and discovered the little
                                    touchs that makes you stay unique.
                                </p>

                                <div className="d-flex gap-2">
                                    <div className="card">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/homstay-c1fd5.appspot.com/o/test%2Froom%2F4981376c-1d6f-45f9-935e-08a7d85d82a8.jpg?alt=media&token=05f52e33-01a0-4f43-8aae-0eb49123d098" />
                                    </div>

                                    <div className="card">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/homstay-c1fd5.appspot.com/o/test%2Froom%2F184a6a70-c3fd-465a-bc79-230564c3f2b6.jpg?alt=media&token=741479f4-e806-4e89-9f44-a20eff5a90e8" />
                                    </div>

                                    <div className="card">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/homstay-c1fd5.appspot.com/o/test%2Froom%2F66480e69-5c45-4dc3-a4d5-5693889aa8e1.jpg?alt=media&token=51905353-848d-4dac-9c84-e842b6041e94" />
                                    </div>
                                </div>

                                <button className="">View Full Gallery</button>
                            </div>

                            <div className="footer-section">
                                <div className="left">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/homstay-c1fd5.appspot.com/o/67176474ca0ae03b77abe906%2FBusiness%2Flogo%2F94dd8840-8d8d-4eb5-bd62-7bb246ea3066.jpg?alt=media&token=988a5e0a-fca3-4018-86f7-82639952b012" />

                                    <p>
                                        Truvadi Brings you personally travel experiences,
                                        from peaceful beach gateways to exciting City
                                        Adventures. With exclusive offers and expert tips,
                                        we make it easy to plan memorable trips that suit
                                        your style.
                                    </p>
                                </div>
                                <div className="right">
                                    <div>
                                        <h1>Contact Us</h1>

                                        <div className="d-flex">
                                            <i className="fa fa-location-dot"></i>
                                            <h5>Dadpur, Amta, West Bengal, 711401</h5>
                                        </div>

                                        <div className="d-flex">
                                            <i className="fa fa-phone"></i>
                                            <h5>8250795699</h5>
                                        </div>

                                        <div className="d-flex">
                                            <i className="fa fa-envelope"></i>
                                            <h5>ayand269@gmail.com</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </Card> */}
            </div>
        </PageBody>
    );
};

export default Website;
