import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './Layout';
import { InitDashboard } from './assets/js/init';
import { RouteData } from './DataTypes/Global';
import { RouteList } from './Route';
import Login from './Views/Auth';
import useAuthStore from './Zustand/Auth';
import ToastProvider from './Components/Toast/ToastProvider';

const App: React.FC = () => {
    const { isLoggedIn } = useAuthStore();
    useEffect(() => {
        InitDashboard();
    }, []);

    const createRoute = (routeData: Array<RouteData>): React.ReactElement => {
        return (
            <>
                {routeData.map((item, index) => {
                    return (
                        <Route
                            path={item.path}
                            key={index}
                            element={item.hasChildren ? undefined : item.element}
                        >
                            {item.hasChildren ? createRoute(item.children) : null}
                        </Route>
                    );
                })}
            </>
        );
    };

    return (
        <ToastProvider>
            <BrowserRouter>
                <Routes>
                    {!isLoggedIn ? (
                        <Route
                            path="/"
                            element={<Login />}
                        />
                    ) : (
                        <Route
                            path="/"
                            element={<Layout />}
                        >
                            {createRoute(RouteList)}
                        </Route>
                    )}
                </Routes>
            </BrowserRouter>
        </ToastProvider>
    );
};

export default App;
