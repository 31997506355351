import React from 'react';

type Props = {
    label: string;
    checked?: boolean;
    onChnage?: (value: boolean) => void;
};
const CheckBox: React.FC<Props> = ({ label, checked, onChnage = () => {} }) => {
    return (
        <div className="d-flex gap-1">
            <input
                type="checkbox"
                checked={checked}
                onChange={(e) => onChnage(e.target.checked)}
            />
            {label}
        </div>
    );
};

export default CheckBox;
