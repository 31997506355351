import React, { Component, forwardRef, useImperativeHandle, useState } from 'react';
import { CustomeInputRef } from './Input';

interface DropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    label?: string;
    errorMessage?: string;
    value: string | number;
    checkValid?: (value: string | number) => boolean;
    leftIcon?: React.ReactNode | string;
    rightIcon?: React.ReactNode | string;
    onRightIconClick?: () => void;
    placeholder?: string;
    options: Array<Record<string, any>>;
    valueKey?: string;
    labelKey?: string;
    uppercase?: boolean;
}

const Dropdown = forwardRef<CustomeInputRef, DropdownProps>(
    (
        {
            label,
            errorMessage,
            value,
            onChange = () => {},
            checkValid,
            leftIcon,
            rightIcon,
            onRightIconClick,
            placeholder,
            options,
            valueKey = 'value',
            labelKey = 'label',
            uppercase = true,
            ...props
        },
        ref
    ) => {
        const [isError, setIsError] = useState<boolean>(false);
        const [isValid, setIsValid] = useState<boolean>(false);

        useImperativeHandle(
            ref,
            () => {
                return {
                    validate() {
                        let validRequired = value !== '' && props.required ? true : false;
                        let customeCheckValid = checkValid ? checkValid(value) : true;

                        setIsError(!(validRequired && customeCheckValid));
                        setIsValid(validRequired && customeCheckValid);

                        return validRequired && customeCheckValid;
                    }
                };
            },
            [value, props.required, checkValid]
        );

        return (
            <div className="mb-2">
                {label ? (
                    <label className={`form-label ${uppercase ? 'text-uppercase' : ''}`}>
                        {label}
                    </label>
                ) : null}

                <div className="input-group">
                    {leftIcon ? <div className="input-group-text">{leftIcon}</div> : null}
                    <select
                        {...props}
                        className={`form-select ${uppercase ? 'text-uppercase' : ''} ${
                            props.className
                        }  ${isError ? 'error' : ''} ${isValid ? 'success' : ''}`}
                        value={value}
                        onChange={(e) => {
                            onChange(e);
                        }}
                    >
                        <option
                            disabled
                            value=""
                        >
                            {placeholder ?? 'Select'}
                        </option>
                        {options.map((item, index) => {
                            return (
                                <option
                                    value={item[valueKey]}
                                    key={index}
                                >
                                    {item[labelKey]}
                                </option>
                            );
                        })}
                    </select>

                    {rightIcon ? (
                        <button
                            onClick={onRightIconClick}
                            className="input-group-text"
                            type="button"
                        >
                            {rightIcon}
                        </button>
                    ) : null}
                </div>

                <small
                    className="mt-1"
                    style={{
                        display: isError ? 'block' : 'none',
                        color: '#ef4d56'
                    }}
                >
                    {errorMessage ?? `${label} is Required.`}
                </small>
            </div>
        );
    }
);

export default Dropdown;
