import React from 'react';

type Props = {
    options: Array<Record<string, any>>;
    onChange?: (value: any) => void;
    value: any;
    valueKey?: string;
    labelKey?: string;
};

const ButtonDropdown: React.FC<Props> = ({
    options,
    onChange = () => {},
    value,
    valueKey = 'value',
    labelKey = 'label'
}) => {
    return (
        <div className="dropdown">
            <a
                className="btn bt btn-light dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                href="#"
            >
                <i className="icofont-calendar fs-5 me-1"></i>
                {options.find((it) => it[valueKey] === value)?.[labelKey]}
                <i className="las la-angle-down ms-1"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-end">
                {options.map((item, index) => {
                    return (
                        <a
                            className="dropdown-item"
                            key={index}
                            onClick={() => onChange(item[valueKey])}
                        >
                            {item[labelKey]}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default ButtonDropdown;
