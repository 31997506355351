import {
    CustomerBuisnessProfile,
    CustomerHomestayListDataType,
    CustomerRoomListDataType,
    CustomerSingleDataType,
    HomestayDataType
} from '../DataTypes/Customer';
import { Res } from '../DataTypes/Global';
import { UserApi } from '../Utils/Apis';

async function getCustomerSingle(id: string): Promise<Res<CustomerSingleDataType>> {
    let endpoint = `customer/${id}`;
    return UserApi.get(endpoint);
}

async function getCustomerHomestay(
    id: string
): Promise<Res<Array<CustomerHomestayListDataType>>> {
    let endpoint = `customer/homestay/${id}`;
    return UserApi.get(endpoint);
}

async function getCustomerRooms(
    id: string
): Promise<Res<Array<CustomerRoomListDataType>>> {
    let endpoint = `customer/room/${id}`;
    return UserApi.get(endpoint);
}

async function getCustomerBuisnessProfile(
    id: string
): Promise<Res<CustomerBuisnessProfile>> {
    let endpoint = `customer/business/${id}`;
    return UserApi.get(endpoint);
}

async function createCustomerHomestay(
    id: string,
    data: HomestayDataType
): Promise<Res<HomestayDataType>> {
    let endpoint = `customer/single-homestay/${id}`;
    return UserApi.post(endpoint, data);
}

const CustomerService = {
    getCustomerSingle,
    getCustomerHomestay,
    getCustomerRooms,
    getCustomerBuisnessProfile,
    createCustomerHomestay
};

export default CustomerService;
