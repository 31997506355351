export const AmenitiesData = [
    { title: 'Free Wi-Fi', slug: 'free-wifi' },
    { title: 'Air Conditioning', slug: 'air-conditioning' },
    { title: 'Television', slug: 'television' },
    { title: 'Minibar', slug: 'minibar' },
    { title: 'Coffee Maker', slug: 'coffee-maker' },
    { title: 'Desk and Chair', slug: 'desk-chair' },
    { title: 'Safe', slug: 'safe' },
    { title: 'Hair Dryer', slug: 'hair-dryer' },
    { title: 'Iron ', slug: 'iron' },
    { title: 'Complimentary Toiletries', slug: 'complimentary-toiletries' },
    { title: '24/7 Service', slug: 'room-service' },
    { title: 'Daily cleaning ', slug: 'daily-cleaning' },
    { title: 'Fitness Center', slug: 'fitness-center' },
    { title: 'Shared Kitchen', slug: 'shared-kitchen' },
    { title: 'Access to Common Areas', slug: 'common-areas' },
    { title: 'Laundry Facilities', slug: 'laundry-facilities' },
    { title: 'Local Tour Information', slug: 'local-tour-info' },
    { title: 'Personalized Welcome', slug: 'personalized-welcome' },
    { title: 'Outdoor Space/Garden', slug: 'outdoor-space' }
];
