import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import './DatatableStyle.css';
import Dropdown from '../Inputs/Dropdown';
import Input from '../Inputs/Input';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import axios from 'axios';
import useAuthStore from '../../Zustand/Auth';
import { Pegination, Res } from '../../DataTypes/Global';

export interface DataTableWithPagingThead {
    title: any;
    style?: CSSProperties;
    className?: string;
    slug: string;
    hide?: boolean;
}

interface DataTableProps<TBodyData> {
    thead: DataTableWithPagingThead[];
    makeTableData: (item: TBodyData, index: number) => Record<string, any>;
    upperCase?: boolean;
    defaultItemShowCount?: number;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
}

const entriesCountData = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
];

const DatatableWithPaging = <T extends Record<string, any>>({
    thead,
    makeTableData,
    upperCase = true,
    defaultItemShowCount = 10,
    method = 'GET',
    url
}: DataTableProps<T>) => {
    const { userToken } = useAuthStore();

    const [entriesCount, setEntriesCount] = useState<number>(defaultItemShowCount);
    const [bodyData, setBodyData] = useState<Array<Record<string, any>>>([]);
    const [backupBodyData, setBackupBodyData] = useState<Array<Record<string, any>>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [search, setSearch] = useState<string>('');
    const [isSearchEnable, setIsSearchEnable] = useState<boolean>(false);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [totalPage, setTotalPage] = useState<number>(0);

    useEffect(() => {
        if (isSearchEnable) {
            if (search.trim() != '') {
                const regex = new RegExp(search.trim(), 'i');

                const searchData = backupBodyData.filter((item) => {
                    return Object.values(item).some((value) =>
                        regex.test(value.toString())
                    );
                });

                console.log('searchData', searchData);
                setBodyData(searchData);
            } else {
                setBodyData(backupBodyData);
            }
        } else {
            setIsSearchEnable(true);
        }
    }, [search]);

    useEffect(() => {
        getTableData(currentPage);
    }, [entriesCount]);

    const getTableData = (page: number) => {
        axios
            .request<Res<Array<any>, Pegination>>({
                method: method,
                url: `https://hotel.startnd.com/api/v1/admin/${url}?limit=${entriesCount}&page=${page}`,
                headers: { authorization: userToken }
            })
            .then((result) => {
                let { data } = result;
                if (data.status) {
                    let newData = data.data.reduce((prev, current, currentIndex) => {
                        let newItem = makeTableData(current, currentIndex);
                        newItem.pageNo = page;
                        return [...prev, newItem];
                    }, new Array<Record<string, any>>());

                    setBodyData((state) => {
                        let filterData = [
                            ...state.filter((it) => it.pageNo !== page),
                            ...newData
                        ];

                        return filterData;
                    });

                    setBackupBodyData((state) => {
                        let filterData = [
                            ...state.filter((it) => it.pageNo !== page),
                            ...newData
                        ];

                        return filterData;
                    });
                    setTotalElements(data.total);
                    setTotalPage(data.totalPage);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const startEntry = (currentPage - 1) * entriesCount + 1;
    const endEntry = Math.min(currentPage * entriesCount, totalElements);

    return (
        <div style={{ overflow: 'scroll' }}>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <Dropdown
                        options={entriesCountData}
                        value={entriesCount}
                        onChange={(e) => setEntriesCount(Number(e.target.value))}
                    />
                    <span className="mb-2 ms-1 table-secondary-text">
                        entries per page
                    </span>
                </div>

                <div className="d-flex align-items-center">
                    <span className="mb-2 me-1 table-secondary-text">Search:</span>
                    <Input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        uppercase={false}
                    />
                </div>
            </div>
            <div className="custom-datatable">
                <div className="custom-tb-list custom-tb-header">
                    <div className="custome-tb-item">Sl. No.</div>
                    {thead.map((item, index) => {
                        if (item.hide) return;
                        return (
                            <div
                                key={index}
                                className="custome-tb-item"
                            >
                                {item.title}
                            </div>
                        );
                    })}
                </div>

                {bodyData
                    .filter((it) => it.pageNo === currentPage)
                    .map((item, index) => {
                        return (
                            <div
                                className="custom-tb-list custom-tb-body"
                                key={index}
                            >
                                <div
                                    className={`custome-tb-item ${
                                        upperCase ? 'text-uppercase' : ''
                                    }`}
                                >
                                    {startEntry + index}
                                </div>
                                {thead.map((it, key) => {
                                    if (it.hide) return;

                                    return (
                                        <div
                                            key={key}
                                            className={`custome-tb-item ${
                                                upperCase ? 'text-uppercase' : ''
                                            }`}
                                        >
                                            {item[it.slug]}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
            </div>

            <div className="d-flex justify-content-between align-items-center pagination-control mt-2">
                <span>
                    Showing {startEntry} to {endEntry} of {totalElements} entries
                </span>

                <PaginationControl
                    page={currentPage}
                    between={3}
                    total={totalElements}
                    limit={entriesCount}
                    changePage={(page) => {
                        getTableData(page);
                        setCurrentPage(page);
                    }}
                    ellipsis={1}
                    last={true}
                />
            </div>
        </div>
    );
};

export default DatatableWithPaging;
