import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle';

export const InitDashboard = () => {
    try {
        var collapsedToggle = document.querySelector('.mobile-menu-btn');
        const h = document.querySelector('.startbar-overlay');
        
        const changeSidebarSize = () => {
            310 <= window.innerWidth && window.innerWidth < 1440
                ? document.body.setAttribute('data-sidebar-size', 'collapsed')
                : document.body.setAttribute('data-sidebar-size', 'default');
        };

        collapsedToggle?.addEventListener('click', function () {
            if (document.body.getAttribute('data-sidebar-size') === 'collapsed') {
                document.body.setAttribute('data-sidebar-size', 'default');
            } else {
                document.body.setAttribute('data-sidebar-size', 'collapsed');
            }
        });

        if (h) {
            h.addEventListener('click', () => {
                document.body.setAttribute('data-sidebar-size', 'collapsed');
            });
        }

        // window.addEventListener('resize', changeSidebarSize);
        // changeSidebarSize();
    } catch (e) {}

    try {
        const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltips.forEach((e) => new bootstrap.Tooltip(e));

        const popoverTriggers = document.querySelectorAll('[data-bs-toggle="popover"]');
        popoverTriggers.forEach((e) => new bootstrap.Popover(e));
    } catch (e) {}

    function windowScroll() {
        var e = document.getElementById('topbar-custom');
        if (e) {
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                e.classList.add('nav-sticky');
            } else {
                e.classList.remove('nav-sticky');
            }
        }
    }

    window.addEventListener('scroll', (e) => {
        e.preventDefault();
        windowScroll();
    });

    const initVerticalMenu = () => {
        var e = document.querySelectorAll('.navbar-nav li .collapse');
        document
            .querySelectorAll(".navbar-nav li [data-bs-toggle='collapse']")
            .forEach((e) => {
                e.addEventListener('click', function (e) {
                    e.preventDefault();
                });
            });

        e.forEach((e) => {
            e.addEventListener('show.bs.collapse', function (t) {
                const o = t.target.closest('.collapse.show');
                document.querySelectorAll('.navbar-nav .collapse.show').forEach((e) => {
                    if (e !== t.target && e !== o) {
                        // console.log("tag=rget", e, o)
                        new bootstrap.Collapse(e).hide();
                    }
                });
            });
        });

        // e.forEach((collapseElement) => {
        //     collapseElement.addEventListener('show.bs.collapse', function (t) {
        //         // Find the currently open collapse elements
        //         const openCollapses = document.querySelectorAll('.navbar-nav .collapse.show');
        
        //         openCollapses.forEach((openCollapse) => {
        //             // If the open collapse element is not the one being clicked, hide it
        //             if (openCollapse !== t.target) {
        //                 // Using Bootstrap's Collapse API to hide the element
        //                 // console.log("okkks", bootstrap)
        //                 new bootstrap.Collapse(openCollapse, {
        //                     toggle: false
        //                 }).hide();
        //             }
        //         });
        //     });
        // });

        // const currentUrl = window.location.href.split(/[?#]/)[0];
        // document.querySelectorAll('.navbar-nav a').forEach((t) => {
        //     console.log("t", t.defaultValue)
        //     if (t.href === currentUrl) {
        //         t.classList.add('active');
        //         t.parentNode.classList.add('active');
        //         let parentCollapse = t.closest('.collapse');
        //         while (parentCollapse) {
        //             parentCollapse.classList.add('show');
        //             parentCollapse.parentElement.children[0].classList.add('active');
        //             parentCollapse.parentElement.children[0].setAttribute(
        //                 'aria-expanded',
        //                 'true'
        //             );
        //             parentCollapse = parentCollapse.parentElement.closest('.collapse');
        //         }
        //     }
        // });

        setTimeout(() => {
            const activeItem = document.querySelector('.nav-item li a.active');
            if (activeItem) {
                const mainNavWrapper = document.querySelector(
                    '.main-nav .simplebar-content-wrapper'
                );
                if (mainNavWrapper && activeItem.offsetTop > 100) {
                    mainNavWrapper.scrollTop = activeItem.offsetTop - 300;
                }
            }
        }, 200);
    };

    initVerticalMenu();
};
