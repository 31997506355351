//import liraries
import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';

// create a component
const Layout = () => {
    return (
        <div>
            <Header />

            <Sidebar />

            <div className="page-wrapper">
                <div className="page-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;
