import { RefObject } from 'react';
import { CustomeInputRef } from '../Components/Inputs/Input';
import { ValidateCSVOptionType, ValidateCSVResponse } from '../DataTypes/Global';
import Papa from 'papaparse';

export const checkAllBlankInputWithRef = (
    inputRefs: Array<RefObject<CustomeInputRef>>
): boolean => {
    let allValid = true;

    inputRefs.forEach((inputRef) => {
        if (inputRef.current) {
            const isValid = inputRef.current.validate();
            if (!isValid) {
                allValid = false;
            }
        }
    });

    return allValid;
};

export const validateCSV = (
    file: File,
    options: Array<ValidateCSVOptionType>
): Promise<ValidateCSVResponse> => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: true, // Consider the first row as header
            skipEmptyLines: true, // Skip empty lines
            complete: (result) => {
                const parsedData: Array<any> = result.data;
                if (options.length > 0) {
                    for (let i = 0; i < parsedData.length; i++) {
                        const row = parsedData[i];

                        for (const option of options) {
                            const { field, isEnum, enumValue, split } = option;
                            const cellValue = row[field];

                            // Check if required field is present
                            if (!cellValue || cellValue.trim() === '') {
                                reject({
                                    status: false,
                                    error: `Missing required value for "${field}" in row ${i + 1
                                        }.`
                                });
                                return;
                            }

                            // Check ENUM values if applicable
                            if (isEnum && enumValue) {
                                if (split) {
                                    const values = cellValue
                                        .split(',')
                                        .map((value: any) => value.trim()); // Split by comma and trim spaces
                                    const invalidValues = values.filter(
                                        (value: any) => !enumValue.includes(value)
                                    ); // Find invalid values
                                    if (invalidValues.length > 0) {
                                        reject({
                                            status: false,
                                            error: `Invalid value(s) "${invalidValues.join(
                                                ', '
                                            )}" for "${field}" in row ${i + 1
                                                }. Allowed values are: ${enumValue.join(
                                                    ', '
                                                )}.`
                                        });
                                        return;
                                    }
                                } else {
                                    if (!enumValue.includes(cellValue)) {
                                        reject({
                                            status: false,
                                            error: `Invalid value "${cellValue}" for "${field}" in row ${i + 1
                                                }. Allowed values are: ${enumValue.join(
                                                    ', '
                                                )}.`
                                        });
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }

                resolve({ status: true });
            },
            error: (error) => {
                reject({
                    status: false,
                    error: error.message
                });
            }
        });
    });
};

export const getUrlFromFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent<FileReader>) => {
            // Cast the result to string
            const result = e.target?.result as string;
            resolve(result);
        };

        reader.onerror = (e: ProgressEvent<FileReader>) => {
            // Reject the promise if there is an error
            reject(e);
        };

        reader.readAsDataURL(file);
    });
};

export const numberToWord = (number: number) => {
    const numberMap: Record<number, string> = {
        1: 'Single',
        2: 'Double',
        3: 'Triple',
        4: 'Quadruple',
        5: 'Quintuple',
        6: 'Sextuple',
        7: 'Septuple',
        8: 'Octuple',
        9: 'Nonuple',
        10: 'Decuple'
    };

    return numberMap[number] || 'Invalid number';
};
