import React, { useEffect, useRef, useState } from 'react';
import useAuthStore from '../../Zustand/Auth';
import Input, { CustomeInputRef } from '../../Components/Inputs/Input';
import { ChangePasswordData, UserDataType } from '../../DataTypes/User';
import { checkAllBlankInputWithRef } from '../../Utils/helper';
import AuthService from '../../Services/AuthServices';
import { Res } from '../../DataTypes/Global';
import useToast from '../../Components/Toast/useToast';
import LetterPicture from '../../Components/UI/LetterPicture';

const Profile: React.FC = () => {
    const { userData, setUserData } = useAuthStore();
    const toast = useToast();

    const passwordInputsRef = useRef<Array<React.RefObject<CustomeInputRef>>>([
        React.createRef(),
        React.createRef(),
        React.createRef()
    ]);

    const profileDetailsInputRef = useRef<Array<React.RefObject<CustomeInputRef>>>([
        React.createRef(),
        React.createRef()
    ]);

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [editPersonalDetails, setEditPersonalDetails] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        getProfileDetails();
    }, []);

    const changePasswordHandler = (): void => {
        let allCheck = checkAllBlankInputWithRef(passwordInputsRef.current);

        if (!allCheck) return;

        let data: ChangePasswordData = {
            newPassword: password,
            currentPassword: currentPassword
        };

        AuthService.changePassword(data)
            .then((result) => {
                if (result && result.status) {
                    toast.show(result.message);
                    setCurrentPassword('');
                    setPassword('');
                    setConfirmPassword('');
                }
            })
            .catch((error: Res) => {
                toast.show(error.message, 'danger');
            });
    };

    const getProfileDetails = () => {
        AuthService.getProfile()
            .then((result) => {
                if (result && result.status) {
                    setName(result.data.name);
                    setEmail(result.data.email);
                }
            })
            .catch((e) => console.log('e', e));
    };

    const updateProfile = () => {
        let allCheck = checkAllBlankInputWithRef(profileDetailsInputRef.current);

        if (!allCheck) return;

        // let data: UserDataType = {
        //     name,
        //     email
        // };
        // AuthService.updateProfile(data)
        //     .then((result) => {
        //         if (result && result.status) {
        //             toast.show('Profile Updated Successfully!!!');
        //             if (userData) {
        //                 let newData = {
        //                     ...userData,
        //                     name
        //                 };
        //                 setUserData(newData);
        //             }
        //         }
        //     })
        //     .catch((error: Res) => {
        //         toast.show(error.message, 'danger');
        //     });
    };

    return (
        <div className="container-xxl">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 align-self-center mb-3 mb-lg-0">
                                    <div className="d-flex align-items-center flex-row flex-wrap">
                                        <div className="position-relative me-3">
                                                <img
                                                    src={userData?.image}
                                                    alt=""
                                                    height="120"
                                                    width="120"
                                                    className="rounded-circle"
                                                />
                                                <a
                                                    href="#"
                                                    className="thumb-md justify-content-center d-flex align-items-center bg-primary text-white rounded-circle position-absolute end-0 bottom-0 border border-3 border-card-bg"
                                                >
                                                    <i className="fas fa-camera"></i>
                                                </a>
                                        </div>
                                        <div className="">
                                            <h5 className="fw-semibold fs-22 mb-1">
                                                {userData?.name}
                                            </h5>
                                            <p className="mb-0 text-muted fw-medium">
                                                {userData?.email}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 ms-auto align-self-center"></div>
                                <div className="col-lg-4 align-self-center">
                                    {/* <div className="row row-cols-2">
                                        <div className="col text-end">
                                            <div
                                                id="complete"
                                                className="apex-charts"
                                            ></div>
                                        </div>
                                        <div className="col align-self-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary  d-inline-block"
                                            >
                                                Follow
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-light  d-inline-block"
                                            >
                                                Hire Me
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h4 className="card-title">Personal Information</h4>
                                </div>
                                    <div className="col-auto">
                                        <a
                                            href="#"
                                            className="float-end text-muted d-inline-flex text-decoration-underline"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setEditPersonalDetails(true);
                                            }}
                                        >
                                            <i className="iconoir-edit-pencil fs-18 me-1"></i>
                                            Edit
                                        </a>
                                    </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <p className="text-muted fw-medium mb-3">
                                This section contains your basic personal information. You
                                can view and update details to keep your information
                                up-to-date.
                            </p>
                            {/* <div className="mb-3">
                                <span className="badge bg-transparent border border-light text-gray-700 fs-12 fw-medium mb-1">
                                    Javascript
                                </span>
                                <span className="badge bg-transparent border border-light text-gray-700 fs-12 fw-medium mb-1">
                                    Python
                                </span>
                                <span className="badge bg-transparent border border-light text-gray-700 fs-12 fw-medium mb-1">
                                    Angular
                                </span>
                                <span className="badge bg-transparent border border-light text-gray-700 fs-12 fw-medium mb-1">
                                    Reactjs
                                </span>
                                <span className="badge bg-transparent border border-light text-gray-700 fs-12 fw-medium mb-1">
                                    Flutter
                                </span>
                            </div> */}
                            <ul className="list-unstyled mb-0">
                                <li className="">
                                    <i className="las la-id-card me-2 text-secondary fs-22 align-middle"></i>{' '}
                                    <b> Name </b> :{' '}
                                    {userData?.name}
                                </li>
                                <li className="mt-2">
                                    <i className="las la-envelope me-2 text-secondary fs-22 align-middle"></i>{' '}
                                    <b> Email </b> : {userData?.email}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="tab-content">
                        <div
                            className="tab-pane p-3 active"
                            id="settings"
                            role="tabpanel"
                        >
                            {editPersonalDetails ? (
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="card-title">
                                                    Personal Information
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <Input
                                            containerClass="mb-3"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            layoutHorizontal={true}
                                            label="Full Name"
                                            placeholder="Enter Your Full Name"
                                            required={true}
                                            type="text"
                                            ref={profileDetailsInputRef.current[0]}
                                        />

                                        <Input
                                            containerClass="mb-3"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            layoutHorizontal={true}
                                            label="Email"
                                            placeholder="Enter Your Email"
                                            required={true}
                                            disabled={true}
                                            type="email"
                                            ref={profileDetailsInputRef.current[1]}
                                        />

                                        <div className="form-group row">
                                            <div className="col-lg-2 col-xl-2 offset-lg-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={updateProfile}
                                                >
                                                    Update
                                                </button>
                                            </div>

                                            <div className="col-lg-2 col-xl-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    onClick={() =>
                                                        setEditPersonalDetails(false)
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Change Password</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <Input
                                        containerClass="mb-3"
                                        value={currentPassword}
                                        onChange={(e) =>
                                            setCurrentPassword(e.target.value)
                                        }
                                        layoutHorizontal={true}
                                        label="Current Password"
                                        placeholder="Password"
                                        required={true}
                                        type="password"
                                        ref={passwordInputsRef.current[0]}
                                    />

                                    <Input
                                        containerClass="mb-3"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        layoutHorizontal={true}
                                        label="New Password"
                                        placeholder="New Password"
                                        required={true}
                                        errorMessage="Password must be at least 8 characters long."
                                        checkValid={(val) => {
                                            return val.length >= 8;
                                        }}
                                        type={showPassword ? 'text' : 'password'}
                                        rightIcon={
                                            <i
                                                className={`fas ${
                                                    !showPassword
                                                        ? 'fa-eye'
                                                        : 'fa-eye-slash'
                                                }`}
                                            ></i>
                                        }
                                        onRightIconClick={() =>
                                            setShowPassword((state) => !state)
                                        }
                                        ref={passwordInputsRef.current[1]}
                                    />

                                    <Input
                                        containerClass="mb-3"
                                        value={confirmPassword}
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                        layoutHorizontal={true}
                                        label="Confirm Password"
                                        placeholder="Re-Password"
                                        required={true}
                                        errorMessage="Password and Confirm Password is not matched."
                                        checkValid={(val) => {
                                            return val === password;
                                        }}
                                        type="password"
                                        ref={passwordInputsRef.current[2]}
                                    />

                                    <div className="form-group row">
                                        <div className="col-lg-9 col-xl-8 offset-lg-3">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={changePasswordHandler}
                                            >
                                                Change Password
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Other Settings</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="Email_Notifications"
                                            checked
                                        />
                                        <label className="form-check-label">
                                            Email Notifications
                                        </label>
                                        <span className="form-text text-muted fs-12 mt-0">
                                            Do you need them?
                                        </span>
                                    </div>
                                    <div className="form-check mt-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="API_Access"
                                        />
                                        <label className="form-check-label">
                                            API Access
                                        </label>
                                        <span className="form-text text-muted font-12 mt-0">
                                            Enable/Disable access
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
