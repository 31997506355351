import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { getUrlFromFile } from '../../Utils/helper';
import CommonService from '../../Services/CommonService';

type Props = {
    title: string;
    fileText?: string;
    allowedTypes?: string[];
    value: string;
    onUploadComplete: (url: string) => void;
};
const ImageInput: React.FC<Props> = ({
    title,
    fileText = 'JPEG, PNG',
    allowedTypes = [],
    value = '',
    onUploadComplete
}) => {
    const acceptAttribute = allowedTypes.length > 0 ? allowedTypes.join(',') : undefined;
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [image, setImage] = useState<string | null>(value.trim() === '' ? null : value);
    const [isUploaded, setIsUploaded] = useState<boolean>(true);

    useEffect(() => {
        if (value.trim() !== '') {
            setImage(value);
        }
    }, [value]);

    const uploadImage = (
        file: File,
        setValue: (value: React.SetStateAction<any>) => void
    ) => {
        CommonService.uploadFileToFirebase(file, 'partner')
            .then((result) => {
                if (result && result.status) {
                    setValue(result.data.url ?? '');
                    onUploadComplete(result.data.url ?? '');
                    setIsUploaded(true);
                }
            })
            .catch((error) => console.log('error', error));
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files?.[0];
        if (file) {
            getUrlFromFile(file)
                .then((result) => {
                    setIsUploaded(false);
                    setImage(result);
                    uploadImage(file, setImage);
                })
                .catch((error) => console.log('error', error));
        }
    };

    return (
        <div className="d-flex align-items-center mb-2">
            <div
                style={{
                    height: 70,
                    width: 70,
                    borderWidth: 1,
                    borderRadius: 10,
                    borderColor: '#666',
                    borderStyle: 'dashed',
                    overflow: 'hidden',
                    cursor: 'pointer'
                }}
                onClick={() => fileInputRef.current?.click()}
            >
                <img
                    src={image ? image : require('../../assets/images/icons/image.png')}
                    style={{
                        height: 69,
                        width: 69,
                        objectFit: 'cover',
                        filter: !isUploaded ? 'blur(10px)' : 'blur(0)'
                    }}
                />
            </div>

            <div className="ms-3">
                <h5>{title}</h5>
                <h6 className="mb-0">File Format: {fileText}</h6>
            </div>

            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }} // Hide the file input
                accept={acceptAttribute}
            />
        </div>
    );
};

export default ImageInput;
