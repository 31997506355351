import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { UserDataType } from '../DataTypes/User';

type ThemeType = 'dark' | 'light';

type AuthState = {
    userData: UserDataType | null;
    isLoggedIn: boolean;
    userToken: string | null;
    theme: ThemeType;
};

type AuthActions = {
    setUserData: (userData: UserDataType | null) => void;
    logout: () => void;
    setUserToken: (token: string) => void;
    changeTheme: (theme: ThemeType) => void;
    setLoggedIn: () => void;
};

const useAuthStore = create<AuthState & AuthActions>()(
    persist(
        (set) => ({
            userData: null,
            isLoggedIn: false,
            userToken: '',
            theme: 'light',

            setUserData: (userData) =>
                set(() => ({ userData: userData, isLoggedIn: true })),
            setUserToken: (token) => set(() => ({ userToken: token, isLoggedIn: true })),
            logout: () =>
                set(() => ({ isLoggedIn: false, userData: null, userToken: null })),
            changeTheme: (theme) => set(() => ({ theme })),
            setLoggedIn: () => set(() => ({ isLoggedIn: true }))
        }),
        {
            name: 'auth-store',
            storage: createJSONStorage(() => localStorage),
            version: 0.1,
            migrate: (persistedStore, version) => ({
                ...(persistedStore as AuthState),
                userData: null,
                isLoggedIn: false,
                isLoginTry: false,
                userToken: null
            })
        }
    )
);

export default useAuthStore;
