import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {
    CityDataType,
    Res,
    StatesDataType,
    SubscriptionCategoryDataType
} from '../DataTypes/Global';
import { UserApi } from '../Utils/Apis';
import { storage } from '../Utils/FirebaseConfig';

async function uploadImage(file: File, path: string): Promise<Res<{ link: string }>> {
    let endpoint = `upload/${path}`;

    const formData = new FormData();
    formData.append('file', file);

    return UserApi.post(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

async function uploadFileToFirebase(
    file: File,
    path: string
): Promise<Res<{ url: string }>> {
    try {
        const endpoint = `upload/${path}`;
        const storageRef = ref(storage, `${endpoint}/${file.name}`);

        // Upload the file
        await uploadBytes(storageRef, file);

        // Get the download URL
        const url = await getDownloadURL(storageRef);

        // Return a successful response
        return {
            status: true,
            data: { url },
            message: 'Success'
        };
    } catch (error) {
        // Handle errors and return a failed response
        console.error('Error during file upload:', error);
        return {
            status: false,
            message: 'Error',
            error: error instanceof Error ? error.message : 'Unknown error'
        };
    }
}

async function getAllStates(): Promise<Res<Array<StatesDataType>>> {
    let endpoint = `states`;

    return UserApi.get(endpoint);
}

async function getAllCity(id: string): Promise<Res<Array<CityDataType>>> {
    let endpoint = `states-city/${id}`;

    return UserApi.get(endpoint);
}

async function getAllSubscriptionCategory(): Promise<
    Res<Array<SubscriptionCategoryDataType>>
> {
    let endpoint = `subscription-category`;

    return UserApi.get(endpoint);
}
const CommonService = {
    uploadImage,
    getAllStates,
    getAllCity,
    getAllSubscriptionCategory,
    uploadFileToFirebase
};

export default CommonService;
