import React, { useState } from 'react';
import Input from '../../Components/Inputs/Input';
import useAuthStore from '../../Zustand/Auth';
import { LoginData } from '../../DataTypes/User';
import AuthService from '../../Services/AuthServices';
import useToast from '../../Components/Toast/useToast';
import { Res } from '../../DataTypes/Global';
import { useLocation } from 'react-router-dom';

const Login: React.FC = () => {
    const { setUserToken } = useAuthStore();
    const toats = useToast();

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleLogin = (): void => {
        let data: LoginData = {
            email: username,
            password: password
        };

        AuthService.login(data)
            .then((result) => {
                if (result && result.status) {
                    setUserToken(result.data?.token ?? '');
                }
            })
            .catch((error: Res) => {
                // console.log('error', error);
                toats.show(error.message, 'danger');
            });
    };

    return (
        <div className="container-xxl">
            <div className="row vh-100 d-flex justify-content-center">
                <div className="col-12 align-self-center">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-4 mx-auto">
                                <div className="card">
                                    <div
                                        className="card-body p-0 auth-header-box rounded-top"
                                        style={{ backgroundColor: '#4e6ef1' }}
                                    >
                                        <div className="text-center p-3">
                                            <a
                                                href="index.html"
                                                className="logo logo-admin"
                                            >
                                                <img
                                                    src={require('../../assets/images/logo.png')}
                                                    height="50"
                                                    alt="logo"
                                                    className="auth-logo"
                                                />
                                            </a>
                                            <h4 className="mt-3 mb-1 fw-semibold text-white fs-18">
                                                Let's Get Started
                                            </h4>
                                            <p className="text-white fw-medium mb-0">
                                                Sign in to continue to Truvadi.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <form className="my-4">
                                            <Input
                                                label="Username"
                                                value={username}
                                                onChange={(e) =>
                                                    setUsername(e.target.value)
                                                }
                                                placeholder="Enter Username"
                                                className={'text-transform-none'}
                                            />

                                            <Input
                                                label="Password"
                                                value={password}
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                                placeholder="Enter Password"
                                                type={showPassword ? 'text' : 'password'}
                                                rightIcon={
                                                    <i
                                                        className={`fas ${
                                                            !showPassword
                                                                ? 'fa-eye'
                                                                : 'fa-eye-slash'
                                                        }`}
                                                    ></i>
                                                }
                                                onRightIconClick={() =>
                                                    setShowPassword((state) => !state)
                                                }
                                                className="text-transform-none"
                                            />

                                            <div className="form-group row mt-3">
                                                <div className="col-sm-6">
                                                    <div className="form-check form-switch form-switch-success">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="customSwitchSuccess"
                                                        />
                                                        <label className="form-check-label">
                                                            Remember me
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mb-0 row">
                                                <div className="col-12">
                                                    <div className="d-grid mt-3">
                                                        <button
                                                            className="btn btn-primary"
                                                            type="button"
                                                            onClick={handleLogin}
                                                        >
                                                            Log In{' '}
                                                            <i className="fas fa-sign-in-alt ms-1"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
