import React, { useEffect } from 'react';
import useAuthStore from '../Zustand/Auth';
import { useNavigate } from 'react-router-dom';
import LetterPicture from '../Components/UI/LetterPicture';

const Header: React.FC = () => {
    const navigate = useNavigate();

    const { logout, userData, theme, changeTheme } = useAuthStore();

    useEffect(() => {
        document.documentElement.setAttribute('data-bs-theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        if (theme === 'light') {
            changeTheme('dark');
        } else {
            changeTheme('light');
        }
    };

    const updateGreeting = (): string => {
        const hours = new Date().getHours();
        if (hours < 12) {
            return 'Good Morning';
        } else if (hours < 18) {
            return 'Good Afternoon';
        } else {
            return 'Good Evening';
        }
    };

    return (
        <div className="topbar d-print-none">
            <div className="container-xxl">
                <nav
                    className="topbar-custom d-flex justify-content-between"
                    id="topbar-custom"
                >
                    <ul className="topbar-item list-unstyled d-inline-flex align-items-center mb-0">
                        <li>
                            <button
                                className="nav-link mobile-menu-btn nav-icon"
                                id="togglemenu"
                            >
                                <i className="iconoir-menu-scale"></i>
                            </button>
                        </li>
                        <li className="mx-3 welcome-text">
                            <h3 className="mb-0 fw-bold text-truncate">
                                {updateGreeting()}!
                            </h3>
                        </li>
                    </ul>
                    <ul className="topbar-item list-unstyled d-inline-flex align-items-center mb-0">
                        <li className="topbar-item">
                            <a
                                className="nav-link nav-icon"
                                id="light-dark-mode"
                                onClick={toggleTheme}
                            >
                                <i className="icofont-moon dark-mode"></i>
                                <i className="icofont-sun light-mode"></i>
                            </a>
                        </li>

                        <li className="dropdown topbar-item">
                            <a
                                className="nav-link dropdown-toggle arrow-none nav-icon"
                                data-bs-toggle="dropdown"
                                href="#"
                                role="button"
                                aria-haspopup="false"
                                aria-expanded="false"
                            >
                                <i className="icofont-bell-alt"></i>
                                <span className="alert-badge"></span>
                            </a>
                            <div className="dropdown-menu stop dropdown-menu-end dropdown-lg py-0">
                                <h5 className="dropdown-item-text m-0 py-3 d-flex justify-content-between align-items-center">
                                    Notifications{' '}
                                    <a
                                        href="#"
                                        className="badge text-body-tertiary badge-pill"
                                    >
                                        <i className="iconoir-plus-circle fs-4"></i>
                                    </a>
                                </h5>
                                <ul
                                    className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-1"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            className="nav-link mx-0 active"
                                            data-bs-toggle="tab"
                                            href="#All"
                                            role="tab"
                                            aria-selected="true"
                                        >
                                            All{' '}
                                            <span className="badge bg-primary-subtle text-primary badge-pill ms-1">
                                                24
                                            </span>
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            className="nav-link mx-0"
                                            data-bs-toggle="tab"
                                            href="#Projects"
                                            role="tab"
                                            aria-selected="false"
                                            tabIndex={-1}
                                        >
                                            Projects
                                        </a>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <a
                                            className="nav-link mx-0"
                                            data-bs-toggle="tab"
                                            href="#Teams"
                                            role="tab"
                                            aria-selected="false"
                                            tabIndex={-1}
                                        >
                                            Team
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    className="ms-0"
                                    style={{ maxHeight: 230 }}
                                    data-simplebar
                                >
                                    <div
                                        className="tab-content"
                                        id="myTabContent"
                                    >
                                        <div
                                            className="tab-pane fade show active"
                                            id="All"
                                            role="tabpanel"
                                            aria-labelledby="all-tab"
                                            tabIndex={0}
                                        >
                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    2 min ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-wolf fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            Your order is placed
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            Dummy text of the printing and
                                                            industry.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>

                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    10 min ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-apple-swift fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            Meeting with designers
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            It is a long established fact
                                                            that a reader.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>

                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    40 min ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-birthday-cake fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            UX 3 Task complete.
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            Dummy text of the printing.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>

                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    1 hr ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-drone fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            Your order is placed
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            It is a long established fact
                                                            that a reader.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>

                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    2 hrs ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-user fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            Payment Successfull
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            Dummy text of the printing.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="Projects"
                                            role="tabpanel"
                                            aria-labelledby="projects-tab"
                                            tabIndex={0}
                                        >
                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    40 min ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-birthday-cake fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            UX 3 Task complete.
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            Dummy text of the printing.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>

                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    1 hr ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-drone fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            Your order is placed
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            It is a long established fact
                                                            that a reader.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>

                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    2 hrs ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-user fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            Payment Successfull
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            Dummy text of the printing.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="Teams"
                                            role="tabpanel"
                                            aria-labelledby="teams-tab"
                                            tabIndex={0}
                                        >
                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    1 hr ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-drone fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            Your order is placed
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            It is a long established fact
                                                            that a reader.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>

                                            <a
                                                href="#"
                                                className="dropdown-item py-3"
                                            >
                                                <small className="float-end text-muted ps-2">
                                                    2 hrs ago
                                                </small>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 bg-primary-subtle text-primary thumb-md rounded-circle">
                                                        <i className="iconoir-user fs-4"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2 text-truncate">
                                                        <h6 className="my-0 fw-normal text-dark fs-13">
                                                            Payment Successfull
                                                        </h6>
                                                        <small className="text-muted mb-0">
                                                            Dummy text of the printing.
                                                        </small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <a
                                    href="pages-notifications.html"
                                    className="dropdown-item text-center text-dark fs-13 py-2"
                                >
                                    View All <i className="fi-arrow-right"></i>
                                </a>
                            </div>
                        </li>

                        <li className="dropdown topbar-item">
                            <a
                                className="nav-link dropdown-toggle arrow-none nav-icon"
                                data-bs-toggle="dropdown"
                                href="#"
                                role="button"
                                aria-haspopup="false"
                                aria-expanded="false"
                            >
                                <img
                                    src={userData?.image}
                                    alt=""
                                    className="thumb-lg rounded-circle"
                                />
                            </a>
                            <div className="dropdown-menu dropdown-menu-end py-0">
                                <div className="d-flex align-items-center dropdown-item py-2 bg-secondary-subtle">
                                    <div className="flex-shrink-0">
                                        <img
                                            src={userData?.image}
                                            alt=""
                                            className="thumb-md rounded-circle"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-2 text-truncate align-self-center">
                                        <h6 className="my-0 fw-medium text-dark fs-13">
                                            {userData?.name}
                                        </h6>
                                        <small className="text-muted mb-0">
                                            {userData?.email}
                                        </small>
                                    </div>
                                </div>
                                <div className="dropdown-divider mt-0"></div>

                                {/* {userData?.userType === 'Partner' ? null : ( */}
                                <>
                                    <small className="text-muted px-2 pb-1 d-block">
                                        Account
                                    </small>
                                    <a
                                        className="dropdown-item"
                                        href=""
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/profile');
                                        }}
                                    >
                                        <i className="las la-user fs-18 me-1 align-text-bottom"></i>{' '}
                                        Profile
                                    </a>
                                    <div className="dropdown-divider mb-0"></div>
                                </>
                                {/* )} */}

                                {/* <a
                                    className="dropdown-item"
                                    href="pages-faq.html"
                                >
                                    <i className="las la-wallet fs-18 me-1 align-text-bottom"></i>{' '}
                                    Earning
                                </a>
                                <small className="text-muted px-2 py-1 d-block">
                                    Settings
                                </small>
                                <a
                                    className="dropdown-item"
                                    href="pages-profile.html"
                                >
                                    <i className="las la-cog fs-18 me-1 align-text-bottom"></i>
                                    Account Settings
                                </a>
                                <a
                                    className="dropdown-item"
                                    href="pages-profile.html"
                                >
                                    <i className="las la-lock fs-18 me-1 align-text-bottom"></i>{' '}
                                    Security
                                </a>
                                <a
                                    className="dropdown-item"
                                    href="pages-faq.html"
                                >
                                    <i className="las la-question-circle fs-18 me-1 align-text-bottom"></i>{' '}
                                    Help Center
                                </a> */}

                                <a
                                    className="dropdown-item text-danger"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        logout();
                                    }}
                                >
                                    <i className="las la-power-off fs-18 me-1 align-text-bottom"></i>{' '}
                                    Logout
                                </a>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default Header;
