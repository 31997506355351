import React, { useEffect, useState } from 'react';
import PageBody from '../../Components/UI/PageBody';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerService from '../../Services/CustomerService';
import {
    CustomerHomestayListDataType,
    CustomerRoomListDataType,
    CustomerSingleDataType,
    HomestayDataType
} from '../../DataTypes/Customer';
import BodyCard from '../../Components/UI/BodyCard';
import Datatable from '../../Components/Tables/Datatable';
import { numberToWord } from '../../Utils/helper';
import { PageDataWithEdit } from '../../DataTypes/Global';

const SingleCustomer = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [customerStats, setCustomerStats] = useState<CustomerSingleDataType>({
        buisnessProfile: false,
        homestay: false,
        rooms: false,
        websiteBanner: false,
        websiteConactUs: false,
        websiteConfig: false,
        websiteGallery: false,
        websiteRoom: false
    });
    const [showHomestay, setShowHomestay] = useState<boolean>(false);
    const [showRooms, setShowRooms] = useState<boolean>(false);
    const [allHomestay, setAllHomestay] = useState<Array<CustomerHomestayListDataType>>(
        []
    );
    const [allRooms, setAllRooms] = useState<Array<CustomerRoomListDataType>>([]);

    useEffect(() => {
        getSingleCustomer();
    }, []);

    const getSingleCustomer = () => {
        CustomerService.getCustomerSingle(id ?? '')
            .then((result) => {
                if (result && result.status) {
                    setCustomerStats(result.data);
                }
            })
            .catch((error) => console.log('error', error));
    };

    const getCustomerHomstay = () => {
        CustomerService.getCustomerHomestay(id ?? '')
            .then((result) => {
                if (result && result.status) {
                    setAllHomestay(result.data);
                    setShowHomestay(true);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const getCustomerRooms = () => {
        CustomerService.getCustomerRooms(id ?? '')
            .then((result) => {
                if (result && result.status) {
                    setAllRooms(result.data);
                    setShowRooms(true);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const handleEditHotel = (data: HomestayDataType) => {
        let dataToBeSend: PageDataWithEdit<HomestayDataType> = {
            ...data,
            isEdit: true
        };

        navigate(`/customer-homestay/${id}`, { state: dataToBeSend });
    };

    return (
        <PageBody>
            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <div
                        className={`card selected-card`}
                        onClick={() => navigate(`/customer-buisness/${id}`)}
                    >
                        <div className="card-body">
                            <div className="row d-flex m pb-3">
                                <div className="col-9">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Buisness Profile
                                    </p>
                                    <h3
                                        className={`mt-2 mb-0 fw-bold ${
                                            customerStats.buisnessProfile
                                                ? 'text-done'
                                                : 'text-empty'
                                        }`}
                                    >
                                        {customerStats.buisnessProfile
                                            ? 'Done'
                                            : 'Pending'}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3">
                    <div
                        className={`card selected-card`}
                        onClick={() => {
                            setShowRooms(false);
                            getCustomerHomstay();
                        }}
                    >
                        <div className="card-body">
                            <div className="row d-flex m pb-3">
                                <div className="col-9">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Homestay
                                    </p>
                                    <h3
                                        className={`mt-2 mb-0 fw-bold ${
                                            customerStats.homestay
                                                ? 'text-done'
                                                : 'text-empty'
                                        }`}
                                    >
                                        {customerStats.homestay ? 'Done' : 'Pending'}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3">
                    <div
                        className={`card selected-card`}
                        onClick={() => {
                            setShowHomestay(false);
                            getCustomerRooms();
                        }}
                    >
                        <div className="card-body">
                            <div className="row d-flex m pb-3">
                                <div className="col-9">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Rooms
                                    </p>
                                    <h3
                                        className={`mt-2 mb-0 fw-bold ${
                                            customerStats.rooms
                                                ? 'text-done'
                                                : 'text-empty'
                                        }`}
                                    >
                                        {customerStats.rooms ? 'Done' : 'Pending'}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3">
                    <div
                        className={`card selected-card`}
                        onClick={() => {
                            navigate(`/customer-website/${id}`);
                        }}
                    >
                        <div className="card-body">
                            <div className="row d-flex m pb-3">
                                <div className="col-9">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Website
                                    </p>
                                    <h3
                                        className={`mt-2 mb-0 fw-bold ${
                                            customerStats.websiteBanner &&
                                            customerStats.websiteConactUs &&
                                            customerStats.websiteConfig &&
                                            customerStats.websiteGallery &&
                                            customerStats.websiteRoom
                                                ? 'text-done'
                                                : 'text-empty'
                                        }`}
                                    >
                                        {customerStats.websiteBanner ? 'Done' : 'Pending'}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showHomestay ? (
                    <BodyCard
                        title="All Homestays"
                        headerRightContent={
                            allHomestay.length > 0 ? (
                                <div className="row g-2">
                                    <button
                                        type="button"
                                        className="btn btn-primary d-flex align-items-center"
                                        onClick={() =>
                                            navigate(`/customer-homestay/${id}`)
                                        }
                                    >
                                        <i className="fa-solid fa-plus me-1"></i>
                                        Add Hopmestay
                                    </button>
                                </div>
                            ) : null
                        }
                    >
                        {allHomestay.length > 0 ? (
                            <Datatable
                                thead={[
                                    {
                                        title: 'Sl. No.',
                                        slug: 'id'
                                    },
                                    {
                                        title: 'Name',
                                        slug: 'name'
                                    },
                                    {
                                        title: 'Mobile No.',
                                        slug: 'phno'
                                    },
                                    {
                                        title: 'Address',
                                        slug: 'address'
                                    },
                                    {
                                        title: 'Action',
                                        slug: 'action'
                                    }
                                ]}
                                makeTableData={(item, index) => {
                                    return {
                                        id: index + 1,
                                        name: item.hotelName,
                                        phno: item.phoneNo,
                                        address: `${item.address1}, ${item.address2}, ${item.cityName}, ${item.stateName}`,
                                        action: (
                                            <div className="d-flex">
                                                <a
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <i className="las la-trash-alt la-2x text-danger"></i>
                                                </a>

                                                <a
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEditHotel(
                                                            item as unknown as HomestayDataType
                                                        );
                                                    }}
                                                >
                                                    <i className="las la-edit la-2x ms-2 text-primary"></i>
                                                </a>
                                            </div>
                                        )
                                    };
                                }}
                                tbody={allHomestay}
                            />
                        ) : (
                            <div className="d-flex flex-column">
                                <button
                                    className="btn btn-primary align-self-center d-flex align-items-center"
                                    type="button"
                                    onClick={() => navigate(`/customer-homestay/${id}`)}
                                >
                                    <i className="fa-solid fa-plus me-1"></i>
                                    Add Hopmestay
                                </button>
                            </div>
                        )}
                    </BodyCard>
                ) : null}

                {showRooms ? (
                    <BodyCard
                        title="All Rooms"
                        headerRightContent={
                            allRooms.length > 0 ? (
                                <div className="row g-2">
                                    <button
                                        type="button"
                                        className="btn btn-primary d-flex align-items-center"
                                    >
                                        <i className="fa-solid fa-plus me-1"></i>
                                        Add Rooms
                                    </button>
                                </div>
                            ) : null
                        }
                    >
                        {allRooms.length > 0 ? (
                            <Datatable
                                thead={[
                                    {
                                        title: 'Sl. No.',
                                        slug: 'id'
                                    },
                                    {
                                        title: 'Name',
                                        slug: 'name'
                                    },
                                    {
                                        title: 'Room Type',
                                        slug: 'type'
                                    },
                                    {
                                        title: 'Price',
                                        slug: 'price'
                                    },
                                    {
                                        title: 'Action',
                                        slug: 'action'
                                    }
                                ]}
                                makeTableData={(item, index) => {
                                    return {
                                        id: index + 1,
                                        name: `${item.hotelName} (${item.roomNo})`,
                                        type: numberToWord(item.bed),
                                        price: item.price * item.person,
                                        action: (
                                            <div className="d-flex">
                                                <a
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <i className="las la-trash-alt la-2x text-danger"></i>
                                                </a>

                                                <a
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <i className="las la-edit la-2x ms-2 text-primary"></i>
                                                </a>
                                            </div>
                                        )
                                    };
                                }}
                                tbody={allRooms}
                            />
                        ) : (
                            <div className="d-flex flex-column">
                                <button
                                    className="btn btn-primary align-self-center d-flex align-items-center"
                                    type="button"
                                >
                                    <i className="fa-solid fa-plus me-1"></i>
                                    Add Rooms
                                </button>
                            </div>
                        )}
                    </BodyCard>
                ) : null}
            </div>
        </PageBody>
    );
};

export default SingleCustomer;
