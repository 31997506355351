import { RouteData } from '../DataTypes/Global';
import Profile from '../Views/Auth/Profile';
import AllCustomer from '../Views/Customer';
import BuisnessProfile from '../Views/Customer/BuisnessProfile';
import CreateHomestay from '../Views/Customer/CreateHomestay';
import SingleCustomer from '../Views/Customer/SingleCustomer';
import Website from '../Views/Customer/Website';
import Dashboard from '../Views/Dashboard';
import useAuthStore from '../Zustand/Auth';

const { userData } = useAuthStore.getState();

export const RouteList: Array<RouteData> = [
    {
        path: '/',
        name: 'Dashboard',
        element: <Dashboard />,
        icon: 'fas fa-home'
    },
    {
        path: '/customers',
        name: 'Customers',
        icon: 'fas fa-user-friends',
        element: <AllCustomer />
    },
    {
        path: '/customer-single/:id',
        name: 'Single Customers',
        element: <SingleCustomer />,
        hideOnSidebar: true
    },
    {
        path: '/customer-buisness/:id',
        name: 'Buisness Profile',
        element: <BuisnessProfile />,
        hideOnSidebar: true
    },
    {
        path: '/customer-website/:id',
        name: 'Website',
        element: <Website />,
        hideOnSidebar: true
    },
    {
        path: '/customer-homestay/:id',
        name: 'Website',
        element: <CreateHomestay />,
        hideOnSidebar: true
    },
    {
        path: '/profile',
        name: 'Profile',
        element: <Profile />,
        hideOnSidebar: true
    }
];
