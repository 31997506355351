//import liraries
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    DashboardCountType,
    DashboardEventType,
    PartnerLoginCount
} from '../../DataTypes/Dashboard';
import DashboardService from '../../Services/DashboardService';
import { Res } from '../../DataTypes/Global';
import { Card } from 'react-bootstrap';
import BodyCard from '../../Components/UI/BodyCard';
import Datatable from '../../Components/Tables/Datatable';
import moment from 'moment';
import ButtonDropdown from '../../Components/UI/ButtonDropdown';
import useAuthStore from '../../Zustand/Auth';
import { useLocation } from 'react-router-dom';
import useToast from '../../Components/Toast/useToast';

const Dashboard: React.FC = () => {
    const { userData } = useAuthStore();
    const location = useLocation();
    const toast = useToast();

    const queryParams = new URLSearchParams(location.search);
    const loginType = queryParams.get('type');

    const [dashboardCounts, setDashboardCounts] = useState<DashboardCountType>({
        totalClient: 0,
        totalPartner: 0,
        unlistedEquity: 0,
        mutualFundModel: 0,
        totalBonds: 0,
        totalCompany: 0
    });
    const [allEvents, setAllEvents] = useState<Array<DashboardEventType>>([]);
    const [isEventLoaded, setIsEventLoaded] = useState<boolean>(false);
    const [partnerLoginTime, setPartnerLoginTime] = useState<string>('today');
    const [partnerLoginCount, setPartnerLoginCount] = useState<PartnerLoginCount>({
        today: 0,
        week: 0,
        month: 0
    });

    useLayoutEffect(() => {
        getDashboardCount();
        getDashboardEvents();
        getPartnerLoginCount();
    }, []);

    // useLayoutEffect(() => {

    // },[])

    const getPartnerLoginCount = () => {
        DashboardService.getPartnerLoginCount()
            .then((result) => {
                if (result && result.status) {
                    setPartnerLoginCount(result.data);
                }
            })
            .catch((error) => {
                console.log('error');
            });
    };

    const getDashboardCount = () => {
        DashboardService.getDashboardCount()
            .then((result) => {
                if (result && result.status) {
                    setDashboardCounts(result.data);
                }
            })
            .catch((error: Res) => console.log('error', error));
    };

    const getDashboardEvents = () => {
        DashboardService.getDashboardEvents()
            .then((result) => {
                if (result) {
                    setAllEvents(result.data ?? []);
                }
                setIsEventLoaded(true);
            })
            .catch((error) => {
                setIsEventLoaded(true);
                console.log('error', error);
            });
    };


    const getLoginCount = (): number => {
        if (partnerLoginTime === 'today') return partnerLoginCount.today;
        if (partnerLoginTime === 'week') return partnerLoginCount.week;
        if (partnerLoginTime === 'month') return partnerLoginCount.month;

        return 0;
    };


    return (
        <div className="container-xxl">
            <div className="row">
                <div className="col-md-6 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row d-flex justify-content-center m pb-3">
                                <div className="col-9">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Total Clients
                                    </p>
                                    <h3 className="mt-2 mb-0 fw-bold">
                                        {dashboardCounts.totalClient}
                                    </h3>
                                </div>

                                <div className="col-3 align-self-center">
                                    <div className="d-flex justify-content-center align-items-center thumb-xl bg-light rounded-circle mx-auto">
                                        <i className="iconoir-hexagon-dice h1 align-self-center mb-0 text-secondary"></i>
                                    </div>
                                </div>
                            </div>

                            {/* <p className="mb-0 text-truncate text-muted mt-3">
                                <span className="text-success">8.5%</span>
                                New Sessions Today
                            </p> */}
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row d-flex justify-content-center pb-3">
                                <div className="col-9">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Total Partners
                                    </p>
                                    <h3 className="mt-2 mb-0 fw-bold">
                                        {dashboardCounts.totalPartner}
                                    </h3>
                                </div>

                                <div className="col-3 align-self-center">
                                    <div className="d-flex justify-content-center align-items-center thumb-xl bg-light rounded-circle mx-auto">
                                        <i className="iconoir-hexagon-dice h1 align-self-center mb-0 text-secondary"></i>
                                    </div>
                                </div>
                            </div>

                            {/* <p className="mb-0 text-truncate text-muted mt-3">
                                <span className="text-success">1.5%</span>
                                Weekly Avg.Sessions
                            </p> */}
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row d-flex justify-content-center pb-3">
                                <div className="col-9">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Total Unlisted Equity
                                    </p>
                                    <h3 className="mt-2 mb-0 fw-bold">
                                        {dashboardCounts.unlistedEquity}
                                    </h3>
                                </div>

                                <div className="col-3 align-self-center">
                                    <div className="d-flex justify-content-center align-items-center thumb-xl bg-light rounded-circle mx-auto">
                                        <i className="iconoir-hexagon-dice h1 align-self-center mb-0 text-secondary"></i>
                                    </div>
                                </div>
                            </div>

                            {/* <p className="mb-0 text-truncate text-muted mt-3">
                                <span className="text-success">1.5%</span>
                                Weekly Avg.Sessions
                            </p> */}
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row d-flex justify-content-center pb-3">
                                <div className="col-9">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Total Active Mutual Funds
                                    </p>
                                    <h3 className="mt-2 mb-0 fw-bold">
                                        {dashboardCounts.mutualFundModel}
                                    </h3>
                                </div>

                                <div className="col-3 align-self-center">
                                    <div className="d-flex justify-content-center align-items-center thumb-xl bg-light rounded-circle mx-auto">
                                        <i className="iconoir-hexagon-dice h1 align-self-center mb-0 text-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row d-flex justify-content-center pb-3">
                                <div className="col-6">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Partner Login
                                    </p>
                                    <h3 className="mt-2 mb-0 fw-bold">
                                        {getLoginCount()}
                                    </h3>
                                </div>

                                <div className="col-auto justify-content-end">
                                    {/* <div className="d-flex justify-content-center align-items-center thumb-xl bg-light rounded-circle mx-auto">
                                        <i className="iconoir-hexagon-dice h1 align-self-center mb-0 text-secondary"></i>
                                    </div> */}
                                    <ButtonDropdown
                                        options={[
                                            { label: 'Today', value: 'today' },
                                            { label: 'This Week', value: 'week' },
                                            { label: 'This Month', value: 'month' }
                                        ]}
                                        value={partnerLoginTime}
                                        onChange={(val) => setPartnerLoginTime(val)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-md-6 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row d-flex justify-content-center border-dashed-bottom pb-3">
                                <div className="col-9">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        Bounce Rate
                                    </p>
                                    <h3 className="mt-2 mb-0 fw-bold">36.45%</h3>
                                </div>

                                <div className="col-3 align-self-center">
                                    <div className="d-flex justify-content-center align-items-center thumb-xl bg-light rounded-circle mx-auto">
                                        <i className="iconoir-percentage-circle h1 align-self-center mb-0 text-secondary"></i>
                                    </div>
                                </div>
                            </div>

                            <p className="mb-0 text-truncate text-muted mt-3">
                                <span className="text-danger">8%</span>
                                Up Bounce Rate Weekly
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>

            <BodyCard title="Upcoming Events">
                {isEventLoaded ? (
                    <Datatable
                        thead={[
                            {
                                title: 'Sl No.',
                                slug: 'index'
                            },
                            {
                                title: 'Name',
                                slug: 'displayName'
                            },
                            {
                                title: 'Email',
                                slug: 'email'
                            },
                            {
                                title: 'Date',
                                slug: 'date'
                            },
                            {
                                title: 'User Type',
                                slug: 'userType'
                            },
                            {
                                title: 'Event Type',
                                slug: 'eventType'
                            }
                        ]}
                        tbody={allEvents}
                        makeTableData={(item, index) => {
                            return {
                                index: index + 1,
                                displayName: `${item.firstname}${
                                    item.middlename && item.middlename !== ''
                                        ? ' ' + item.middlename
                                        : ''
                                } ${item.lastname}`,
                                email: item.email,
                                userType: item.userType,
                                eventType: item.eventType,
                                date: moment(item.eventDate).format('DD MMM YYYY')
                            };
                        }}
                    />
                ) : null}
            </BodyCard>
            {/* <div className="row justify-content-center">
                <div className="col-md-6 col-lg-8">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h4 className="card-title">Audience Overview</h4>
                                </div>

                                <div className="col-auto">
                                    <div className="dropdown">
                                        <a
                                            href="#audience_overview"
                                            className="btn bt btn-light dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="icofont-calendar fs-5 me-1"></i>
                                            This Year
                                            <i className="las la-angle-down ms-1"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Today
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Last Week
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Last Month
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                This Year
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div
                                id="audience_overview"
                                className="apex-charts"
                            ></div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col">
                                    <p className="text-dark mb-0 fw-semibold fs-14">
                                        New Visitors
                                    </p>
                                    <h2 className="mt-0 mb-0 fw-bold">1,282</h2>
                                </div>

                                <div className="col-auto align-self-center">
                                    <div className="img-group d-flex">
                                        <a
                                            className="user-avatar position-relative d-inline-block"
                                            href="#audience_overview"
                                        >
                                            <img
                                                src="assets/images/users/avatar-1.jpg"
                                                alt="avatar"
                                                className="thumb-md shadow-sm rounded-circle"
                                            />
                                        </a>
                                        <a
                                            className="user-avatar position-relative d-inline-block ms-n2"
                                            href="#audience_overview"
                                        >
                                            <img
                                                src="assets/images/users/avatar-2.jpg"
                                                alt="avatar"
                                                className="thumb-md shadow-sm rounded-circle"
                                            />
                                        </a>
                                        <a
                                            className="user-avatar position-relative d-inline-block ms-n2"
                                            href="#audience_overview"
                                        >
                                            <img
                                                src="assets/images/users/avatar-4.jpg"
                                                alt="avatar"
                                                className="thumb-md shadow-sm rounded-circle"
                                            />
                                        </a>
                                        <a
                                            className="user-avatar position-relative d-inline-block ms-n2"
                                            href="#audience_overview"
                                        >
                                            <img
                                                src="assets/images/users/avatar-3.jpg"
                                                alt="avatar"
                                                className="thumb-md shadow-sm rounded-circle"
                                            />
                                        </a>
                                        <a className="user-avatar position-relative d-inline-block ms-1">
                                            <span className="thumb-md shadow-sm justify-content-center d-flex align-items-center bg-info-subtle rounded-circle fw-semibold fs-6">
                                                +6
                                            </span>
                                        </a>
                                    </div>
                                    <small className="text-muted">Logined Visitors</small>
                                </div>
                            </div>

                            <div
                                id="visitors_report"
                                className="apex-charts mb-2"
                            ></div>
                            <button
                                type="button"
                                className="btn btn-primary w-100 btn-lg fs-14"
                            >
                                More Detail{' '}
                                <i className="fa-solid fa-arrow-right-long"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="row">
                <div className="col-lg-6">
                    <div className="card card-h-100">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h4 className="card-title">
                                        Browser Used & Traffic Reports
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className="table-responsive browser_users">
                                <table className="table mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="border-top-0">Browser</th>
                                            <th className="border-top-0">Sessions</th>
                                            <th className="border-top-0">Bounce Rate</th>
                                            <th className="border-top-0">Transactions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img
                                                    src="assets/images/logos/chrome.png"
                                                    height="24"
                                                    className="me-2"
                                                />
                                                Chrome
                                            </td>
                                            <td>
                                                10853
                                                <small className="text-muted">
                                                    (52%)
                                                </small>
                                            </td>
                                            <td> 52.80%</td>
                                            <td>
                                                566
                                                <small className="text-muted">
                                                    (92%)
                                                </small>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <img
                                                    src="assets/images/logos/micro-edge.png"
                                                    height="24"
                                                    className="me-2"
                                                />
                                                Microsoft Edge
                                            </td>
                                            <td>
                                                2545
                                                <small className="text-muted">
                                                    (47%)
                                                </small>
                                            </td>
                                            <td> 47.54%</td>
                                            <td>
                                                498
                                                <small className="text-muted">
                                                    (81%)
                                                </small>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <img
                                                    src="assets/images/logos/in-explorer.png"
                                                    height="24"
                                                    className="me-2"
                                                />
                                                Internet-Explorer
                                            </td>
                                            <td>
                                                1836
                                                <small className="text-muted">
                                                    (38%)
                                                </small>
                                            </td>
                                            <td> 41.12%</td>
                                            <td>
                                                455
                                                <small className="text-muted">
                                                    (74%)
                                                </small>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <img
                                                    src="assets/images/logos/opera.png"
                                                    height="24"
                                                    className="me-2"
                                                />
                                                Opera
                                            </td>
                                            <td>
                                                1958
                                                <small className="text-muted">
                                                    (31%)
                                                </small>
                                            </td>
                                            <td> 36.82%</td>
                                            <td>
                                                361
                                                <small className="text-muted">
                                                    (61%)
                                                </small>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <img
                                                    src="assets/images/logos/chrome.png"
                                                    height="24"
                                                    className="me-2"
                                                />
                                                Chrome
                                            </td>
                                            <td>
                                                10853
                                                <small className="text-muted">
                                                    (52%)
                                                </small>
                                            </td>
                                            <td> 52.80%</td>
                                            <td>
                                                566
                                                <small className="text-muted">
                                                    (92%)
                                                </small>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="card card-h-100">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h4 className="card-title">Total Visits</h4>
                                </div>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="border-top-0">Channel</th>
                                            <th className="border-top-0">Sessions</th>
                                            <th className="border-top-0">Prev.Period</th>
                                            <th className="border-top-0">% Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <a
                                                    href="#audience_overview"
                                                    className="text-primary"
                                                >
                                                    Organic search
                                                </a>
                                            </td>
                                            <td>
                                                10853
                                                <small className="text-muted">
                                                    (52%)
                                                </small>
                                            </td>
                                            <td>
                                                566
                                                <small className="text-muted">
                                                    (92%)
                                                </small>
                                            </td>
                                            <td>
                                                {' '}
                                                52.80%{' '}
                                                <i className="fas fa-caret-up text-success font-16"></i>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <a
                                                    href="#audience_overview"
                                                    className="text-primary"
                                                >
                                                    Direct
                                                </a>
                                            </td>
                                            <td>
                                                2545
                                                <small className="text-muted">
                                                    (47%)
                                                </small>
                                            </td>
                                            <td>
                                                498
                                                <small className="text-muted">
                                                    (81%)
                                                </small>
                                            </td>
                                            <td>
                                                {' '}
                                                -17.20%{' '}
                                                <i className="fas fa-caret-down text-danger font-16"></i>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <a
                                                    href="#audience_overview"
                                                    className="text-primary"
                                                >
                                                    Referal
                                                </a>
                                            </td>
                                            <td>
                                                1836
                                                <small className="text-muted">
                                                    (38%)
                                                </small>
                                            </td>
                                            <td>
                                                455
                                                <small className="text-muted">
                                                    (74%)
                                                </small>
                                            </td>
                                            <td>
                                                {' '}
                                                41.12%{' '}
                                                <i className="fas fa-caret-up text-success font-16"></i>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <a
                                                    href="#audience_overview"
                                                    className="text-primary"
                                                >
                                                    Email
                                                </a>
                                            </td>
                                            <td>
                                                1958
                                                <small className="text-muted">
                                                    (31%)
                                                </small>
                                            </td>
                                            <td>
                                                361
                                                <small className="text-muted">
                                                    (61%)
                                                </small>
                                            </td>
                                            <td>
                                                {' '}
                                                -8.24%{' '}
                                                <i className="fas fa-caret-down text-danger font-16"></i>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <a
                                                    href="#audience_overview"
                                                    className="text-primary"
                                                >
                                                    Social
                                                </a>
                                            </td>
                                            <td>
                                                1566
                                                <small className="text-muted">
                                                    (26%)
                                                </small>
                                            </td>
                                            <td>
                                                299
                                                <small className="text-muted">
                                                    (49%)
                                                </small>
                                            </td>
                                            <td>
                                                {' '}
                                                29.33%{' '}
                                                <i className="fas fa-caret-up text-success"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p className="m-0 fs-12 fst-italic ps-2 text-muted">
                                Last data updated - 13min ago{' '}
                                <a
                                    href="#audience_overview"
                                    className="link-danger ms-1 "
                                >
                                    <i className="align-middle iconoir-refresh"></i>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h4 className="card-title">Traffic Sources</h4>
                                </div>

                                <div className="col-auto">
                                    <div className="dropdown">
                                        <a
                                            href="#audience_overview"
                                            className="btn bt btn-light dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="icofont-search-user fs-5 me-1"></i>
                                            Direct
                                            <i className="las la-angle-down ms-1"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Email
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Referral
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Direct
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Organic
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Campaign
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div>
                                <div
                                    id="traffic_sources"
                                    className="apex-charts d-block w-90 mx-auto"
                                ></div>
                                <hr className="hr-dashed border-secondary w-25 mt-0 mx-auto" />
                            </div>
                            <div className="text-center">
                                <h4>Direct Visitors</h4>
                                <p className="text-muted mt-2">
                                    This is a simple hero unit, a simple jumbotron-style
                                    component
                                </p>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary px-3 mt-2"
                                >
                                    More details
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-8">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h4 className="card-title">
                                        Organic Traffic In World
                                    </h4>
                                </div>

                                <div className="col-auto">
                                    <div className="dropdown">
                                        <a
                                            href="#audience_overview"
                                            className="btn bt btn-light dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i className="icofont-calendar fs-5 me-1"></i>
                                            Today
                                            <i className="las la-angle-down ms-1"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Today
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Last Week
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                Last Month
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                href="#audience_overview"
                                            >
                                                This Year
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div
                                        id="map_2"
                                        style={{ height: 320 }}
                                    ></div>
                                </div>

                                <div className="col-lg-4 align-self-center">
                                    <div className="d-flex align-items-center my-3">
                                        <img
                                            src="assets/images/flags/us_flag.jpg"
                                            className="thumb-sm align-self-center rounded-circle"
                                            alt="..."
                                        />
                                        <div className="flex-grow-1 ms-2">
                                            <h5 className="mb-1">35,365</h5>
                                            <p className="text-muted mb-0">
                                                USA . Last Month
                                                <span className="text-success">
                                                    2.5%
                                                    <i className="mdi mdi-arrow-up"></i>
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center my-3">
                                        <img
                                            src="assets/images/flags/germany_flag.jpg"
                                            className="thumb-sm align-self-center rounded-circle"
                                            alt="..."
                                        />
                                        <div className="flex-grow-1 ms-2">
                                            <h5 className="mb-1">24,865</h5>
                                            <p className="text-muted mb-0">
                                                Germany . Last Month
                                                <span className="text-success">
                                                    1.2%
                                                    <i className="mdi mdi-arrow-up"></i>
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center my-3">
                                        <img
                                            src="assets/images/flags/spain_flag.jpg"
                                            className="thumb-sm align-self-center rounded-circle"
                                            alt="..."
                                        />
                                        <div className="flex-grow-1 ms-2">
                                            <h5 className="mb-1">18,369</h5>
                                            <p className="text-muted mb-0">
                                                Spain . Last Month
                                                <span className="text-success">
                                                    0.8%
                                                    <i className="mdi mdi-arrow-up"></i>
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center my-3">
                                        <img
                                            src="assets/images/flags/baha_flag.jpg"
                                            className="thumb-sm align-self-center rounded-circle"
                                            alt="..."
                                        />
                                        <div className="flex-grow-1 ms-2">
                                            <h5 className="mb-1">11,325</h5>
                                            <p className="text-muted mb-0">
                                                Bahamas . Last Month
                                                <span className="text-success">
                                                    2.5%
                                                    <i className="mdi mdi-arrow-up"></i>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default Dashboard;
