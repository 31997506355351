import React from 'react';

type Props = {
    title?: string;
    children?: React.ReactNode;
    className?: string;
    titleStyle?: React.CSSProperties;
};
const Card: React.FC<Props> = ({ title, children, className = '', titleStyle }) => {
    return (
        <div className={`card ${className}`}>
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h6
                            className="card-title"
                            style={titleStyle}
                        >
                            {title}
                        </h6>
                    </div>
                </div>
            </div>

            <div className="card-body pt-0">{children}</div>
        </div>
    );
};

export default Card;
