import { Res } from '../DataTypes/Global';
import { ChangePasswordData, LoginData, LoginRes, UserDataType } from '../DataTypes/User';
import { UserApi } from '../Utils/Apis';

async function login(data: LoginData): Promise<Res<LoginRes>> {
    let endpoint = 'login';
    return UserApi.post(endpoint, data);
}

async function getProfile(): Promise<Res<UserDataType>> {
    let endpoint = 'profile';
    return UserApi.get(endpoint);
}

async function changePassword(data: ChangePasswordData): Promise<Res> {
    let endpoint = 'password-update';
    return UserApi.put(endpoint, data);
}

async function updateProfile(data: UserDataType): Promise<Res<UserDataType>> {
    let endpoint = 'profile-update';
    return UserApi.put(endpoint, data);
}

const AuthService = {
    login,
    getProfile,
    changePassword,
    updateProfile
};

export default AuthService;
