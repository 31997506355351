import React, { useCallback, useState } from 'react';
import ToastContext, { Toast, ToastType } from './ToastContext';

type ToastProviderProps = {
    children: React.ReactNode;
};

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const [toasts, setToasts] = useState<Array<Toast>>([]);

    const show = useCallback(
        (
            message: string,
            type: ToastType = 'success',
            autoHide = true,
            timeout = 3000
        ) => {
            const id = Number(`${new Date().getTime()}${Math.random()}`);
            setToasts((prevToasts) => [...prevToasts, { id, message, type }]);

            if (autoHide) {
                setTimeout(() => {
                    setToasts((prevToasts) =>
                        prevToasts.filter((toast) => toast.id !== id)
                    );
                }, timeout);
            }
        },
        []
    );

    const removeToast = useCallback(
        (id: number) => {
            setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
        },
        [toasts]
    );

    return (
        <ToastContext.Provider value={{ toasts, show }}>
            {children}
            <div className="toast-container position-fixed top-0 end-0 p-3">
                {toasts.map((item, index) => {
                    return (
                        <div
                            className={`toast show align-items-center text-bg-${item.type} border-0`}
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                            key={index}
                        >
                            <div className="d-flex">
                                <div className="toast-body">{item.message}</div>
                                <button
                                    type="button"
                                    className="btn-close btn-close-white me-2 m-auto"
                                    onClick={() => removeToast(item.id)}
                                ></button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </ToastContext.Provider>
    );
};

export default ToastProvider;
