import React, { useEffect, useState } from 'react';

export type StepCheckCompleteRef = {
    isComplete: () => boolean;
};

export type StepsDataType = {
    name: string;
    completed?: boolean;
};

interface BaseProps {
    steps: Array<StepsDataType>;
    tabClickable?: boolean;
    stepsElement: Array<React.ReactNode>;
    onSubmit?: () => void;
    onNext?: (currentIndex: number, nextIndex: number) => void;
    defaultActiveStepIndex?: number;
    showTitle?: boolean;
}

interface NextWithoutCheck extends BaseProps {
    nextAfterComplete?: false;
    onNextCheckForComplete?: never;
}

interface NextWithCheck extends BaseProps {
    nextAfterComplete?: true;
    onNextCheckForComplete: (stepIndex: number) => boolean;
}

type Props = NextWithoutCheck | NextWithCheck;

const Wizard: React.FC<Props> = ({
    steps,
    tabClickable,
    stepsElement,
    onSubmit = () => {},
    nextAfterComplete,
    onNextCheckForComplete,
    onNext = () => {},
    defaultActiveStepIndex,
    showTitle = true
}) => {
    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        if (defaultActiveStepIndex !== undefined && defaultActiveStepIndex !== null) {
            if (defaultActiveStepIndex < 0 || defaultActiveStepIndex > steps.length - 1) {
                throw new Error('Please give valid step index');
            }

            setActiveTab(defaultActiveStepIndex);
        }
    }, [defaultActiveStepIndex]);

    return (
        <div>
            <nav>
                <div
                    className="nav nav-tabs"
                    id="nav-tab"
                >
                    {steps.map((item, index) => {
                        return (
                            <a
                                className={`nav-link py-2 ${
                                    activeTab === index
                                        ? 'active'
                                        : tabClickable
                                        ? ''
                                        : 'disabled'
                                }`}
                                id="step1-tab"
                                data-bs-toggle="tab"
                                href="#"
                                key={index}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveTab(index);
                                }}
                                style={{
                                    color: item.completed ? '#95e3b1' : undefined
                                }}
                            >
                                {item.name}

                                {item.completed ? (
                                    <i className="fas fa-check-circle ms-2"></i>
                                ) : null}
                            </a>
                        );
                    })}
                </div>
            </nav>

            <div
                className="tab-content"
                id="nav-tabContent"
            >
                {stepsElement.map((item, index) => {
                    return (
                        <div
                            className={`tab-pane ${activeTab === index ? 'active' : ''}`}
                            key={index}
                        >
                            {showTitle ? (
                                <h4 className="card-title my-4 fs-15">
                                    {steps[index].name}
                                </h4>
                            ) : null}

                            {item}

                            <div className="mt-2">
                                {index !== 0 ? (
                                    <button
                                        type="button"
                                        className="btn btn-secondary float-start"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveTab(index - 1);
                                        }}
                                    >
                                        Previous
                                    </button>
                                ) : null}

                                <button
                                    type="button"
                                    className="btn btn-primary float-end"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (index === steps.length - 1) {
                                            onSubmit();
                                            return;
                                        }

                                        if (nextAfterComplete) {
                                            if (
                                                onNextCheckForComplete &&
                                                !onNextCheckForComplete(index)
                                            ) {
                                                return;
                                            }
                                        }

                                        onNext(index, index + 1);
                                        setActiveTab(index + 1);
                                    }}
                                >
                                    {index === steps.length - 1 ? 'Submit' : 'Next'}
                                </button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Wizard;
