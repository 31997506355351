import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse
} from 'axios';
import useAuthStore from '../Zustand/Auth';

export const getInstance = (baseUrl: string): AxiosInstance => {
    const _instance = axios.create({ baseURL: `${baseUrl}/api/v1/admin/` });

    _instance.interceptors.request.use((config) => {
        const token = useAuthStore.getState().userToken;
        if (config?.headers && token) {
            config.headers['authorization'] = token;
        }
        config.headers['Access-Control-Allow-Origin'] = baseUrl;

        return config;
    });

    _instance.interceptors.response.use(
        (response: AxiosResponse) => {
            return Promise.resolve(response.data);
        },
        async (error: AxiosError) => {
            return Promise.reject(error.response?.data);
        }
    );

    return _instance;
};

// export const uploadFile = async (
//     baseUrl: string,
//     endpoint: string,
//     file: File
// ): Promise<any> => {
//     try {
        
//         return JSON.parse(res.data);
//     } catch (error) {
//         console.log('error', error);
//     }
// };
