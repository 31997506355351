import React, { useState } from 'react';
import PageBody from '../../Components/UI/PageBody';
import BodyCard from '../../Components/UI/BodyCard';
import DatatableWithPaging from '../../Components/Tables/DatatableWithPaging';
import { useNavigate } from 'react-router-dom';

const AllCustomer = () => {
    const navigate = useNavigate();
    return (
        <PageBody>
            <BodyCard title="All Customers">
                <DatatableWithPaging
                    thead={[
                        {
                            title: 'Name',
                            slug: 'name'
                        },
                        {
                            title: 'Mobile No.',
                            slug: 'phno'
                        },
                        {
                            title: 'Action',
                            slug: 'action'
                        }
                    ]}
                    makeTableData={(item, index) => {
                        return {
                            name: `${item.firstName} ${item.lastName}`,
                            phno: item.mobileNo,
                            action: (
                                <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                        navigate(`/customer-single/${item._id}`)
                                    }
                                >
                                    View
                                </button>
                            )
                        };
                    }}
                    url="customer"
                />
            </BodyCard>
        </PageBody>
    );
};

export default AllCustomer;
